import React from 'react';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gcomponents/utils';
import { HeaderTitle, Text } from '$gcomponents/primitives';
import { ColAuto, Row, Div, SPACE, Space } from '$gstyles';
import { Wrapper, Cell } from './styles';
import { configs } from '$configs';

interface FormSectionProps {
  FORM: Array<InputRowModel>;
  formik: any;
  children?: React.ReactNode;
  inputType?: string;
  title?: string;
  titleButton?: React.ReactNode;
  className?: string;
  isHidden?: boolean;
  marginBottom?: string;
  callback?: Function;
  afterRender?: React.ReactNode;
  lastColumn?: React.ReactNode;
  [x: string]: any;
}

const FormSection: React.FC<FormSectionProps> = ({
  FORM,
  formik,
  title,
  titleButton,
  children,
  inputType,
  isHidden,
  marginBottom = SPACE.XLARGE,
  callback,
  className,
  afterRender,
  lastColumn,
  ...rest
}) => {
  if (isHidden) return null;

  const rendered = (
    <>
      {title && (
        <HeaderTitle>
          <div>{<Text k={title} />}</div>
          {titleButton && titleButton}
        </HeaderTitle>
      )}
      <Space.Bottom margin={marginBottom}>
        <Wrapper className="form-group" {...rest}>
          {children ||
            FORM.map((row, i) => {
              if (row.isHidden && row.isHidden(formik.values)) return null;
              return (
                <React.Fragment key={i}>
                  {row.title && (
                    <HeaderTitle>
                      <Div marginTop={SPACE.LARGE}>{row.title}</Div>
                    </HeaderTitle>
                  )}
                  <Row className={`row ${row.className || ''}`}>
                    {row.items.map((col, j) => {
                      if (col.isHidden && col.isHidden(formik.values)) return null;
                      const marginTop = `${row.lineSpace || configs.display.formSpace || 12}px`;
                      return (
                        <Cell
                          {...(row.style && { customStyle: row.style })}
                          key={j}
                          className="col"
                          gridSize={col.gridSize || 6}
                          padding={SPACE.SMALL}
                          style={{ marginTop }}>
                          {input.generateInput(col, formik, inputType, callback)}
                        </Cell>
                      );
                    })}
                    {lastColumn && i + 1 === FORM.length ? (
                      <ColAuto className="col">{lastColumn}</ColAuto>
                    ) : null}
                  </Row>
                </React.Fragment>
              );
            })}
        </Wrapper>
        {afterRender ? afterRender : null}
      </Space.Bottom>
    </>
  );

  return className ? <div className={className}>{rendered}</div> : rendered;
};

export default FormSection;
