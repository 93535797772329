import React from 'react';
import GroupModel from '$business/models/group';
import { GroupBoxWrapper } from './styles';

interface GroupBoxProps {
  group: GroupModel;
  onClick: Function;
}

const GroupBox: React.FC<GroupBoxProps> = ({ group, onClick }) => {
  return (
    <GroupBoxWrapper onClick={() => onClick(group)}>
      <div className="name">{group.name}</div>
      <div className="count">({group.count || 0})</div>
    </GroupBoxWrapper>
  );
};

export default GroupBox;
