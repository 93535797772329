import { configs } from '$configs';

export const generateSelects = (pageSize, defaultValue = false) => {
  const selects: Array<boolean> = [];
  for (let i = 0; i < pageSize; i++) {
    selects.push(defaultValue);
  }

  return selects;
};

export const generateCollapses = (pageSize, defaultValue = false) => {
  const collapses: Array<boolean> = [];
  for (let i = 0; i < pageSize; i++) {
    collapses.push(defaultValue);
  }

  return collapses;
};

export const getCellLabel = (cells, value) => {
  const cell = cells.find((c) => c.value === value);

  return cell?.label;
};

export const sortTable = (data, key, order) => {
  const newData = [...data];
  const orderValueLess = order === 'ASC' ? -1 : 1;
  const orderValueMore = order === 'ASC' ? 1 : -1;
  newData.sort((a, b) => {
    if (a[key] < b[key]) {
      return orderValueLess;
    }
    if (a[key] > b[key]) {
      return orderValueMore;
    }
    return 0;
  });

  return newData;
};

export const excludeColumns = (TABLE, styles) => {
  const exclusionKey = styles.exclusion;
  if (!exclusionKey) return TABLE;
  const exclusions = configs.excludeColumns ? configs.excludeColumns[exclusionKey] || [] : [];
  if (!exclusions.length) return TABLE;

  return TABLE.filter((cell) => !exclusions.includes(cell.value));
};

export const excludeTabs = (tabs, key) => {
  if (!key) return tabs;
  const exclusions = configs.excludeTabs ? configs?.excludeTabs[key] || [] : [];
  if (!exclusions.length) return tabs;

  return tabs.filter((cell) => !exclusions.includes(cell.value));
};
