import _ from 'lodash';
import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { searchSharp, closeSharp } from 'ionicons/icons';
import Autosuggest from 'react-autosuggest';

import { fetchApi } from '$gbusiness/services/api';
import { Wrapper } from './styles';

interface AutoCompleteProps {
  AC: any;
  endpoint: string;
  history?: any;
  width?: string;
  height?: string;
  autoSelect?: boolean;
  fixedList?: Array<any>;
  placeholder?: string;
  throttle?: number;
  position?: string;
  hideSearchIcon?: boolean;
  extraParam?: any;
  useFormData?: boolean;
  listKey?: string;
}

interface StateModel {
  value: any;
  query: string;
  suggestions: Array<any>;
}

class Autocomplete extends Component<AutoCompleteProps> {
  state: StateModel = {
    value: null,
    query: '',
    suggestions: [],
  };

  static defaultProps: AutoCompleteProps = {
    AC: {},
    endpoint: '',
    width: 'auto',
    height: '42px',
    placeholder: '',
    throttle: 300,
    fixedList: [],
    useFormData: false,
    position: 'bottom',
    listKey: 'list',
  };

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
      query: newValue,
    });
  };

  onSelect = (suggestion, i) => {
    const { AC, history: h } = this.props;
    if (AC.onSelect) AC.onSelect(this.state.value, h);
    if (AC.clearOnSelect) {
      this.onClearInput();
    }
  };

  renderSuggestion = (suggestion) => <div>{this.props.AC.renderSuggestion(suggestion)}</div>;

  getSuggestionValue = (e) => {
    const { AC } = this.props;
    if (AC.getLabel) return AC.getLabel(e);
    return '';
  };

  loadSuggestions = async (query) => {
    const { AC, fixedList, autoSelect, useFormData, listKey } = this.props;
    const { extraParam = {}, method, deriveRaw, minChar = 2, isPublic, onResponse, clearOnFetch } = AC;
    if (query && query.length < minChar) return;

    const param = {
      ...extraParam,
      query,
    };

    const response = await fetchApi({
      url: this.props.endpoint + (method === 'GET' ? `/${query}` : ''),
      ...(useFormData ? { formData: param } : { param }),
      ...(isPublic && { isPublic: true }),
      method: method || 'POST',
    });
    if (onResponse) {
      onResponse(response, this.state.query);
      if (clearOnFetch) this.onClearInput();
    }
    const fixedResult = fixedList?.filter((l) => l.name.toLowerCase() === query.toLowerCase());

    const suggestionList = deriveRaw ? deriveRaw(response) : response[listKey || 'list'] || [];
    const suggestions = [...(fixedResult || []), ...suggestionList];

    if (suggestions.length === 1 && autoSelect) {
      const loneItem = suggestions[0];
      const { AC, history: h } = this.props;
      if (AC.onSelect) AC.onSelect(loneItem, h);
      if (AC.clearOnSelect) {
        this.onClearInput();
      }
      return;
    }

    this.setState({
      suggestions,
    });
  };

  onSuggestionHighlighted = ({ suggestion }) => {
    if (!suggestion) return;

    if (this.props.position === 'top') {
      this.setState({ value: suggestion });
    } else {
      this.setState({ value: suggestion, query: this.props.AC.getLabel(suggestion) });
    }
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.debouncedLoadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [] });
  };

  onClearInput = () => {
    this.setState({ value: null, query: '' });
  };

  onBlurSelect = (e, { highlightedSuggestion: item }) => {
    const { history } = this.props;
    if (item && this.onSelect) this.onSelect(item, history);
  };

  onKeyPress = (e) => {
    const { AC } = this.props;
    if ((e.key === 'Enter' || e.keyCode === 13) && AC.onEnter) AC.onEnter();
  };

  debouncedLoadSuggestions = _.debounce(this.loadSuggestions, this.props.throttle || 300);

  render() {
    const { query, suggestions } = this.state;
    const { height, width, position, placeholder, hideSearchIcon } = this.props;

    const inputProps = {
      placeholder,
      value: query,
      onChange: this.onChange,
      onBlur: this.onBlurSelect,
      onKeyPress: this.onKeyPress,
    };

    return (
      <>
        <Wrapper
          height={height}
          width={width}
          position={position}
          hideSearchIcon={hideSearchIcon}
          className="ac-wrapper">
          {!hideSearchIcon && <IonIcon icon={searchSharp} slot="start" />}
          <Autosuggest
            id="auto-suggest"
            placeholder={placeholder}
            suggestions={suggestions}
            highlightFirstSuggestion={position === 'top'}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            onSuggestionSelected={this.onSelect}
            onSuggestionHighlighted={this.onSuggestionHighlighted}
            inputProps={inputProps}
          />
          {inputProps.value !== '' && (
            <IonIcon onClick={this.onClearInput} icon={closeSharp} className="pointer clear-button" />
          )}
        </Wrapper>
      </>
    );
  }
}

export default Autocomplete;
