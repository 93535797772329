import MemberModel, { deriveRawToMember } from './member';

export default interface GroupModel {
  id: number;
  name: string;
  count?: number;
  items?: Array<GroupModel>;
  members?: Array<MemberModel>;
}

export const deriveRawToRootGroup = (raw) => ({
  id: raw.root.groupId,
  name: raw.root.name,
  count: raw.count,
  items: raw.tree ? raw.tree.map(deriveRawToGroup) : [],
});

export const deriveRawToGroup = (raw) => ({
  id: raw.groupId,
  name: raw.name,
  count: raw.totalCount,
  ...(raw.items && raw.items.length > 0 && { items: (raw.items || []).map(deriveRawToGroup) }),
  ...(raw.members && raw.members.length > 0 && { members: (raw.members || []).map(deriveRawToMember) }),
});

export const deriveTreeToGroup = (raw) => {
  const rootMembers = {
    id: raw.groupId,
    name: '',
    members: raw.members.map(deriveRawToMember),
  };

  const getChildren = (items) => {
    if (!items) return [];
    let acc = [];
    for (const item of items) {
      if (item.members) acc = acc.concat(item.members.map(deriveRawToMember));
      const children = getChildren((item.items || []).map(deriveRawToGroup));
      if (item.items) acc = acc.concat(children);
    }
    return acc;
  };

  const nextMembers = raw.items.reduce((acc, group) => {
    const { groupId: id, name, items, members } = group;
    return [
      ...acc,
      {
        id,
        name,
        members: [...(members || []).map(deriveRawToMember), ...getChildren(items)],
        //.sort((a, b) => a.name.localeCompare(b.name)),
      },
    ];
  }, []);

  return {
    ...deriveRawToGroup(raw),
    items: [...(rootMembers.members.length ? [rootMembers] : []), ...nextMembers],
  };
};
