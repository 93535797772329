import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenuToggle,
  IonHeader,
  IonToolbar,
  IonImg,
} from '@ionic/react';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { configs } from '$configs';
import { ROUTES } from '$business/enums';
import { Text } from '$intl';

import { Wrapper } from './styles';
import { accessString } from '$gbusiness/helpers/util';
import { Link } from '$gcomponents/primitives';
import UserModel from '$business/models/user';
import { AppVersion } from '$gcomponents/reusables';
import PATH from '$business/enums/paths';
import CurrentStateModel from '$business/models/currentState';

interface MenuProps extends RouteComponentProps {
  menuId: string;
  isLoggedIn: boolean;
  defaultExpanded?: boolean;
  logo?: string;
  currentRoute: string;
  currentState: CurrentStateModel;
  user?: UserModel;
  onLogout: Function;
  hiddenDesktop?: boolean;
  hiddenMobile?: boolean;
}

const Menu: React.FC<MenuProps> = React.memo(
  ({
    menuId,
    logo,
    defaultExpanded = false,
    hiddenDesktop,
    hiddenMobile,
    currentRoute,
    currentState,
    isLoggedIn,
    user,
    onLogout,
    ...rest
  }) => {
    const isDesktop = useMediaQuery(`(min-width:${configs.display.splitPaneThreshold})`);
    const isHidden =
      (hiddenDesktop && isDesktop) || (hiddenMobile && !isDesktop) || (!currentRoute && isDesktop);

    return (
      <Wrapper
        contentId="main"
        menuId={menuId}
        disabled={isHidden || !isLoggedIn}
        swipeGesture={true}
        isProd={process.env.REACT_APP_ENV === 'prod'}
        {...rest}>
        {!isDesktop && (
          <IonHeader>
            <IonToolbar>
              <IonMenuToggle>
                <Link route={ROUTES.DEFAULT_APP_PATH}>
                  <IonImg class="logo" src={logo || ''} />
                </Link>
                <AppVersion />
              </IonMenuToggle>
            </IonToolbar>
          </IonHeader>
        )}
        <IonContent class="outer-content">
          <IonList>
            {ROUTES.PRIVATE_LIST.map((appPage: any, index) => {
              if (!appPage.title) return null;
              if (user?.userLevel && user?.userLevel > appPage.accessLevel) return null;
              if (appPage.path === PATH.ATTENDANCE && !currentState.isLeader) return null;
              const defaultPath = appPage.path + '/' + (appPage.defaultId || '');
              return (
                <IonMenuToggle
                  key={index}
                  autoHide={false}
                  className={accessString(appPage.accessLevel, appPage.feature)}>
                  <IonItem
                    className={
                      currentRoute === appPage.path || currentRoute === defaultPath
                        ? 'selected pointer'
                        : 'pointer'
                    }
                    routerLink={`${appPage.path}${appPage.defaultId ? '/' + appPage.defaultId : ''}`}
                    routerDirection="none"
                    lines="none"
                    detail={false}>
                    {appPage.icon && <appPage.icon />}
                    <IonLabel>
                      <Text k={appPage?.title} />
                    </IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
            <IonItem lines="none" class="pointer" onClick={() => onLogout()} detail={false}>
              <ExitToAppIcon />
              <IonLabel>
                <Text k="MENU.LOG_OUT" />
              </IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </Wrapper>
    );
  },
  (pp, np) => pp.currentRoute === np.currentRoute && pp.isLoggedIn === np.isLoggedIn,
);

export default withRouter(Menu);
