import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { List, ListItem } from '@mui/material';
import { SPACE, WEIGHT } from '$gstyles';
import { Modal } from '$gcomponents/reusables';
import { ellipsis } from '$gstyles/mixins';

export const IonPageWrapper = styled(IonPage)`
  .content {
  }
`;

export const ListWrapper = styled(List)`
  .MuiListItem-root {
    padding: ${SPACE.MEDIUM};
    .MuiAvatar-root {
      width: 40px;
      height: 40px;
    }
  }
`;

export const GridWrapper = styled(List)`
  &.MuiList-root {
    margin: 0 8px;
  }
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;
  margin: ${SPACE.LARGE};
  padding: 1rem;

  .member-box {
    // border: 1px solid #eee;
    border-radius: 7px;
    padding: 1rem;
    &.leader {
      background-color: #ffffcc;
    }
  }

  .MuiAvatar-root {
    margin: auto;
    height: 80px;
    width: 80px;
    text-align: center;
  }
  .info {
    .flex {
      justify-content: space-around;
    }
    font-size: 1.2rem;
    min-height: 57px;
    .value {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .value.name {
      justify-content: center;
    }
    .phone {
      flex: 2;
      padding-right: 24px;
    }
    .email {
      flex: 1;
    }
    svg {
      font-size: 24px;
      margin-right: 4px;
    }
    .name {
      font-size: 1.5rem;
    }
    .email a {
      ${ellipsis}
    }
  }
`;

export const MemberItemWrapper = styled(ListItem)`
  display: flex;
  align-items: center;
  justify-content: flex-between;
  border-top: 1px solid var(--ion-border-color);

  .MuiListItemSecondaryAction-root {
    right: 0;
  }
`;

export const ProfileModalWrapper = styled(Modal)`
  .profile-image {
    margin: auto;
    max-width: 300px;
    > div {
      border: 7px solid #ddd;
      border-radius: 25px;
      background-size: cover;
    }
  }
  .profile-info-section {
    max-width: 340px;
    margin: auto;
    .label {
      font-size: 0.9em;
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
  }
`;
