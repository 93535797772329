import { peopleCircleOutline, personOutline, phonePortraitOutline } from 'ionicons/icons';

import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { requiredValidation, validatePhone } from '$gbusiness/helpers/input';
import InputRowModel from '$gbusiness/models/inputRow';

const WAIT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.MOBILE,
        gridSize: 12,
        label: 'LABEL.PHONE',
        value: 'phone',
        icon: phonePortraitOutline,
        validation: [
          requiredValidation,
          {
            condition: (v) => !validatePhone(v),
            message: 'INVALID_PHONE',
          },
        ],
      },
      {
        ...INPUT_TYPES.LAST_NAME,
        value: 'name',
        gridSize: 12,
        label: 'LABEL.NAME',
        icon: personOutline,
        validation: [requiredValidation],
      },
      {
        value: 'cell',
        gridSize: 12,
        label: 'LABEL.CELL',
        input: INPUT.NUMBERTEXT,
        icon: peopleCircleOutline,
        validation: [requiredValidation],
      },
    ],
  },
];

export default WAIT_FORM;
