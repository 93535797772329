import React from 'react';
import { Avatar } from '@mui/material';
import { Email, Phone } from '@mui/icons-material';

export const profileAvatar = (member, size = 24, displayName = false, forceImage = false) => {
  const sx = { width: size, height: size };
  const showImage = !forceImage || member?.jejik;
  return showImage ? (
    <Avatar
      alt={member.name}
      className={`avatar member ${showImage && 'jejik'}`}
      sx={sx}
      src={`${process.env.REACT_APP_BASE}${member.thumb}`}>
      {member.name}
    </Avatar>
  ) : (
    <Avatar alt={member.name} className={`avatar member ${showImage && 'jejik'}`} sx={sx}>
      {displayName ? member.name : (member.kname || member.name).substr(0, 1)}
    </Avatar>
  );
};

export const emailLink = (email, asString = false, includeIcon = false): any => {
  if (!email) return asString ? '' : null;
  return asString ? (
    `${includeIcon ? <Email color="primary" /> : ''} <a href=${`mailto:${email}`}>${email}</a>`
  ) : (
    <>
      {includeIcon && <Email color="primary" />}
      <a href={`mailto:${email}`}>{email}</a>
    </>
  );
};
export const phoneLink = (phone, asString = false, includeIcon = false): any => {
  if (!phone) return '';
  return asString ? (
    `${includeIcon ? <Phone color="primary" /> : ''} <a href="${`tel:${phone}`}">${phone}</a>`
  ) : (
    <>
      {includeIcon && <Phone color="primary" />}
      <a href={`tel:${phone}`}>{phone}</a>
    </>
  );
};

export const phoneIcon = (phone) => {
  if (!phone) return null;
  return (
    <a href={`tel:${phone}`}>
      <Phone color="primary" />
    </a>
  );
};
