import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { Dispatch } from 'redux';
import { deriveRawToRootGroup, deriveTreeToGroup } from '../../models/group';
import { deriveRawToUser } from '../../models/user';
import { CLEAN_DIRECTORY, DIRECTORY_FAILURE, FETCH_GROUP_SUCCESS, FETCH_USERS_SUCCESS } from './types';
import { PROCESSING } from '$gbusiness/redux/auth/types';
import { handleFail } from '$gbusiness/redux/auth/actions';
import intl from '$gintl';

export function fetchRootGroup(id): any {
  return async (dispatch: Dispatch) => {
    if (!id) return;
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.group.general + id + '/1',
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.root) {
      handleApiFail(dispatch, DIRECTORY_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }
    dispatch({
      type: FETCH_GROUP_SUCCESS,
      group: deriveRawToRootGroup(response),
    });

    return Promise.resolve(response.data);
  };
}

export function fetchGroup(param): any {
  return async (dispatch: Dispatch) => {
    const { groupId } = param;
    if (!groupId) return;
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.member.general,
      method: 'POST',
      formData: {
        ...param,
        psize: 1000,
      },
    });

    if (!response || !response?.tree) {
      handleApiFail(dispatch, DIRECTORY_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }
    const group = deriveTreeToGroup(response.tree);
    dispatch({
      type: FETCH_GROUP_SUCCESS,
      group,
    });

    return Promise.resolve(response.data);
  };
}

export function fetchWaitingUsers(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.users.waiting,
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, DIRECTORY_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }
    dispatch({
      type: FETCH_USERS_SUCCESS,
      users: response.list.map(deriveRawToUser),
    });
  };
}

export function submitAttendance(param, isFormData = false): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await fetchApi({
      url: configs.api.attendance.submit,
      ...(isFormData ? { formData: param } : { param }),
      isPublic: true,
    });

    // Server Error. Network or whatever
    if (!response || !response.success || response.err) {
      handleFail(dispatch, response?.message, 'ERROR.SERVER', 'large');
      return;
    }

    handleApiSuccess(dispatch, null, intl('MESSAGE.ATTENDANCE_SUCCESS'), 'large');
    return Promise.resolve(true);
  };
}

export function dehydrate(): any {
  return { type: CLEAN_DIRECTORY };
}
