import { lockClosedOutline, phonePortraitOutline } from 'ionicons/icons';

import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { requiredValidation, validatePhone } from '$gbusiness/helpers/input';
import InputRowModel from '$gbusiness/models/inputRow';
import { validatePassword } from '$business/helpers/utils';

const ONBOARD_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.MOBILE,
        gridSize: 12,
        label: 'LABEL.PHONE',
        icon: phonePortraitOutline,
        validation: [
          requiredValidation,
          {
            condition: (v) => !validatePhone(v),
            message: 'INVALID_PHONE',
          },
        ],
      },
      {
        ...INPUT_TYPES.PASSWORD,
        icon: lockClosedOutline,
        enterToSubmit: true,
        gridSize: 12,
        position: undefined,
        validation: [
          requiredValidation,
          {
            condition: (v) => !validatePassword(v),
            message: 'INVALID_PASSWORD',
          },
        ],
      },
      {
        input: INPUT.CHECKBOX,
        gridSize: 12,
        label: 'LABEL.CONSENT',
        className: 'consent',
        value: 'consent',
        validation: [requiredValidation],
      },
    ],
  },
];

export default ONBOARD_FORM;
