import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonAlert, IonContent } from '@ionic/react';
import intl, { Text } from '$gintl';

import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { dialog, FormSection, LogoHeader } from '$gcomponents/reusables';
import { Box, SPACE, Space } from '$gstyles';
import PATH from '$business/enums/paths';

import { Button, Link } from '$gcomponents/primitives';
import { Form, Formik } from 'formik';
import ONBOARD_FORM from './onboardForm';
import { input } from '$gbusiness/helpers';
import { initActions } from '$business/redux/init';
import { PENDING_CODE, WAITING, ACTIVE } from '$business/enums/onboardingStatus';
import { OnboardScreenWrapper } from './styles';

interface OnboardScreenProps {
  register: Function;
  screenState: any;
  status: string;
  history: any;
}

const OnboardScreen: React.FC<OnboardScreenProps> = ({ register, status, screenState, history }) => {
  useEffect(() => {
    if (!status) return;
    if (status === PENDING_CODE) history.push(PATH.VERIFY);
    // NEEDS WAIT submit info SCREEN
    else if (status === WAITING) history.push(PATH.WAIT);
    else if (status === ACTIVE) {
      history.push(PATH.LOGIN);
      dialog.alert({ message: 'MESSAGE.ALREADY_EXISTS' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  const onSubmit = (values) => register({ phone: values.mobile, passwd: values.password });

  const validateForm = (values) => input.validateError(ONBOARD_FORM, values);

  return (
    <OnboardScreenWrapper id="forgot-screen">
      <Header title="SCREEN.ONBOARDING.TITLE" />
      <IonContent>
        <IonAlert
          isOpen={screenState !== ''}
          header={intl('MESSAGE.SUBMITTED')}
          message={intl('MESSAGE.PASSWORD_RESET')}
          onDidDismiss={() => {
            history.goBack();
          }}
          buttons={['OK']}
        />
        <Box.LARGE>
          <LogoHeader />
          <Formik
            enableReinitialize={true}
            initialValues={{ state: '' }}
            validate={validateForm}
            onSubmit={(values) => {
              onSubmit(values);
            }}>
            {(formik) => (
              <Box.MEDIUM padding={SPACE.LARGE}>
                <Form>
                  <FormSection
                    title="SCREEN.ONBOARD.TITLE"
                    FORM={ONBOARD_FORM}
                    formik={formik}
                    marginBottom="0"
                  />
                  <Button
                    color="primary"
                    fullWidth
                    onClick={formik.handleSubmit}
                    disabled={!(formik.isValid && formik.dirty)}>
                    <Text k="SCREEN.ONBOARD.SUBMIT_BUTTON" />
                  </Button>
                </Form>
              </Box.MEDIUM>
            )}
          </Formik>
          <Space.Ver margin={SPACE.LARGE}>
            <Link route={PATH.LOGIN} routerDirection="back" button>
              <Text k="SCREEN.ONBOARD.LOGIN_MESSAGE" />
            </Link>
          </Space.Ver>
        </Box.LARGE>
      </IonContent>
    </OnboardScreenWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  status: state.init.status,
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  register: initActions.verifyPhone,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(OnboardScreen));
