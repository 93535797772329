import { FORGOT_SUCCESS } from '$gbusiness/redux/auth/types';
import { ACTIVE, PENDING_CODE, WAITING } from '../../enums/onboardingStatus';

import {
  InitModel,
  INIT_LOGIN_SUCCESS,
  LOAD_HEADERS,
  INIT_STATE,
  INIT_SUCCESS,
  PHONE_VERIFIED_FAILURE,
  PHONE_VERIFIED_SUCCESS,
  CODE_VERIFIED_SUCCESS,
  MESSAGE_SUCCESS,
  USER_EXISTS_SUCCESS,
  SET_MYGROUPS,
} from './types';

export function initReducer(state: InitModel = INIT_STATE, action): InitModel {
  switch (action.type) {
    case INIT_LOGIN_SUCCESS:
      return {
        ...state,
        headers: {
          ...state.headers,
          deviceId: action.deviceId,
          authtoken: action.accessToken,
          userId: action.userId,
        },
      };
    case SET_MYGROUPS:
      return {
        ...state,
        groups: action.user.groups,
        absents: action.absents,
        worships: action.worships,
        isLeader: action.user.groups.length > 0,
      };
    case USER_EXISTS_SUCCESS:
      return {
        ...state,
        status: ACTIVE,
      };
    case MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.message,
      };
    case PHONE_VERIFIED_FAILURE:
      return {
        ...state,
        status: WAITING,
      };
    case PHONE_VERIFIED_SUCCESS:
      return {
        ...state,
        status: PENDING_CODE,
        phone: action.phone,
      };
    case CODE_VERIFIED_SUCCESS:
      return {
        ...state,
        status: ACTIVE,
      };
    case INIT_SUCCESS:
      return {
        ...state,
        initSuccess: true,
      };
    case LOAD_HEADERS:
      return {
        ...state,
        headers: {
          ...state.headers,
          ...action.headers,
        },
      };
    case FORGOT_SUCCESS:
      return {
        ...state,
        public: {
          ...state.public,
          resetRequested: true,
        },
      };
    default:
      return state;
  }
}
