export const SET_TOASTER_SUCCESS = 'SET_TOASTER_SUCCESS';
export const SET_TOASTER_DANGER = 'SET_TOASTER_DANGER';
export const SET_TOASTER_WARNING = 'SET_TOASTER_WARNING';
export const RESET_TOASTER_SUCCESS = 'RESET_TOASTER_SUCCESS';
export const RESET_TOASTER_DANGER = 'RESET_TOASTER_DANGER';
export const RESET_TOASTER_WARNING = 'RESET_TOASTER_WARNING';

// Action Type
export interface SetToasterActionType {
  type: typeof SET_TOASTER_SUCCESS | typeof SET_TOASTER_DANGER | typeof SET_TOASTER_WARNING;
  toast: ToastModel;
}

export interface ResetToasterActionType {
  type: typeof RESET_TOASTER_SUCCESS | typeof RESET_TOASTER_DANGER | typeof RESET_TOASTER_WARNING;
}

export type ToasterActionTypes = SetToasterActionType | ResetToasterActionType;

export interface ToastModel {
  isOpen?: boolean;
  text?: string;
  key?: string;
  className?: string;
  position?: 'TOP' | 'BOTTOM';
  duration?: number;
  autoClose?: boolean;
}

export const defaultToast: ToastModel = {
  isOpen: false,
  text: '',
  key: '',
  position: 'TOP',
  duration: 5000,
  autoClose: true,
};

export interface ToasterModel {
  success: ToastModel;
  warning: ToastModel;
  danger: ToastModel;
}

export const TOASTER_INIT_STATE: ToasterModel = {
  success: defaultToast,
  warning: defaultToast,
  danger: defaultToast,
};
