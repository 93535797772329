import { INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';
import InputRowModel from '$gbusiness/models/inputRow';

const FORGOT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.PASSWORD,
        label: 'INPUT.LABEL.NEW_PASSWORD',
        value: 'password',
        position: 'outlined',
        validation: [
          input.requiredValidation,
          {
            condition: (v) => !input.validatePassword(v),
            message: 'INVALID_PASSWORD',
          },
        ],
        gridSize: 12,
      },
    ],
  },
  {
    items: [
      {
        ...INPUT_TYPES.PASSWORD,
        label: 'INPUT.LABEL.CONFIRM_PASSWORD',
        value: 'passwordConfirm',
        position: 'outlined',
        validation: [
          input.requiredValidation,
          {
            condition: (v, form) => v !== form.password,
            message: 'PASSWORD_MISMATCH',
          },
        ],
        gridSize: 12,
      },
    ],
  },
];

export default FORGOT_FORM;
