import PATH from '$gbusiness/enums/paths';
import UserModel from '$business/models/user';

export default interface CurrentStateModel {
  isLoggedIn: boolean;
  user?: UserModel;
  isOnboarding?: boolean;
  isPendingCode?: boolean;
  isWaiting?: boolean;
  defaultPath?: string;
  groups?: Array<number>;
  isLeader?: boolean;
}

export const initialCurrentState: CurrentStateModel = {
  isLoggedIn: false,
  isOnboarding: true,
  isPendingCode: false,
  isWaiting: true,
  defaultPath: PATH.LOGIN,
  groups: [],
  isLeader: false,
};
