import React from 'react';
import { randomInt } from '$gbusiness/helpers/util';

import { ImagePlaceholder, ImageWrapper } from './styles';
import { Div } from '$gstyles';

interface ImgProps {
  src: string;
  cache?: any;
  placeholder?: string;
  width?: any;
  height?: any;
  alt?: string;
  size?: string;
  position?: string;
  showShadow?: boolean;
  shadowSize?: number;
  [x: string]: any;
}

const Img: React.FC<ImgProps> = ({
  src,
  cache = '',
  placeholder = '',
  width = '100%',
  height = '100%',
  size = 'cover',
  position = 'center',
  showShadow = false,
  shadowSize = 2,
  alt = '',
  ...rest
}) => {
  if (!src) {
    if (!placeholder) return null;
    return (
      <ImagePlaceholder width={width} height={height} {...rest}>
        <div>{placeholder}</div>
      </ImagePlaceholder>
    );
  }
  const number = cache || randomInt(0, 9999);
  return (
    <Div width={width} height={height} {...rest}>
      <ImageWrapper
        showShadow={showShadow}
        shadowSize={shadowSize}
        src={`${src}?${number}`}
        size={size}
        position={position}
        width="100%"
        height="100%"
      />
    </Div>
  );
};

export default Img;
