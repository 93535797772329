import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';
import intl, { Text } from '$gintl';

import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { FormSection, LogoHeader } from '$gcomponents/reusables';
import { Box, SPACE, Space } from '$gstyles';
import PATH from '$business/enums/paths';

import { Button, Link } from '$gcomponents/primitives';
import { initActions } from '$business/redux/init';
import { ConfirmScreenWrapper } from './styles';
import { Form, Formik } from 'formik';
import WAIT_FORM from './waitForm';
import { input } from '$gbusiness/helpers';

interface WaitScreenProps {
  phone: string;
  submitApproval: Function;
  history;
}

const WaitScreen: React.FC<WaitScreenProps> = ({ phone, submitApproval, history }) => {
  const onSubmit = (values) =>
    submitApproval(values).then((result) => {
      history.push(PATH.MESSAGE, { message: intl('MESSAGE.APPROVAL_SUBMITTED') });
    });

  const validateForm = (values) => input.validateError(WAIT_FORM, values);

  return (
    <ConfirmScreenWrapper id="confirm-code-screen">
      <Header title="SCREEN.ONBOARDING.TITLE" />
      <IonContent>
        <Box.LARGE>
          <LogoHeader />
          <Formik
            enableReinitialize={true}
            initialValues={{ phone }}
            validate={validateForm}
            onSubmit={(values) => {
              onSubmit(values);
            }}>
            {(formik) => (
              <Box.MEDIUM padding={SPACE.LARGE}>
                <Form>
                  <FormSection title="SCREEN.WAIT.TITLE" FORM={WAIT_FORM} formik={formik} marginBottom="0" />
                  <Button
                    color="primary"
                    fullWidth
                    onClick={formik.handleSubmit}
                    disabled={!(formik.isValid && formik.dirty)}>
                    <Text k="SCREEN.WAIT.SUBMIT_BUTTON" />
                  </Button>
                </Form>
              </Box.MEDIUM>
            )}
          </Formik>
          <Space.Ver margin={SPACE.LARGE}>
            <Link route={PATH.LOGIN} button>
              <Text k="SCREEN.ONBOARD.LOGIN_MESSAGE" />
            </Link>
          </Space.Ver>
        </Box.LARGE>
      </IonContent>
    </ConfirmScreenWrapper>
  );
};

const mapStateToProps = (state) => ({
  phone: state.localStorage.phone,
});

const mapDispatchToProps = {
  submitApproval: initActions.submitApproval,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(WaitScreen));
