import React from 'react';
import MemberModel from '$business/models/member';
import { emailLink, phoneLink, profileAvatar } from './utils';

interface MemberBoxProps {
  member: MemberModel;
  onClick: Function;
}

const MemberBox: React.FC<MemberBoxProps> = ({ member, onClick }) => {
  return (
    <div className={`${member.isLeader && 'leader'} member-box`}>
      <div className="pointer" onClick={() => onClick(member)}>
        {profileAvatar(member, 128, true, true)}
      </div>
      <div className="info">
        <div className="value name">{member.imagePath ? member.name : <>&nbsp;</>}</div>
        {member.jejik && (
          // <Flex className="flex">
          //   <div className="value phone">
          //     <Phone />
          //     {member.phone}
          //     {/* {phoneLink(member.phone, false, true)} */}
          //   </div>
          //   <div className="value email">
          //     <Email />
          //     {/* {emailLink(member.email, false, true)} */}
          //   </div>
          // </Flex>
          <>
            <div className="value phone">{phoneLink(member.phone, false, true)}</div>
            <div className="value email">{emailLink(member.email, false, true)}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default MemberBox;
