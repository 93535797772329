import SUPPORT_CATEGORY_OPTIONS, { SUPPORT_CATEGORY } from '$business/models/supportCategory';
import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';
import InputRowModel from '$gbusiness/models/inputRow';

const SUPPORT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
        value: 'name',
        label: 'INPUT.LABEL.NAME',
        placeholder: 'INPUT.PLACEHOLDER.OPTIONAL',
        gridSize: 12,
      },
      {
        input: INPUT.SELECT,
        label: 'SCREEN.SUPPORT.CATEGORY',
        options: SUPPORT_CATEGORY_OPTIONS,
        value: 'category',
        validation: [input.requiredValidation],
        onChange: ({ formik, formValues, value }) => {
          if (value === SUPPORT_CATEGORY.DELETE) {
            formik.setFieldValue('body', ' ');
          }
        },
        gridSize: 12,
      },
      {
        input: INPUT.TEXTAREA,
        label: 'SCREEN.SUPPORT.BODY',
        value: 'body',
        rows: 10,
        gridHeight: '200px',
        gridSize: 12,
        validation: [input.requiredValidation],
      },
    ],
  },
];

export default SUPPORT_FORM;
