import GREGISTER_FORM from '$gbox/screens/registerScreen/registerForm';
import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';

const extraFields: Array<InputRowModel> = [
  {
    items: [
      {
        input: INPUT.NUMBER,
        label: 'COMMON.STORE',
        value: 'storeId',
        gridSize: 12,
      },
      {
        input: INPUT.NUMBER,
        label: 'COMMON.FACTORY',
        value: 'factoryId',
        gridSize: 12,
      },
    ],
  },
];

const REGISTER_FORM = [...GREGISTER_FORM, ...extraFields];

export { REGISTER_FORM };
