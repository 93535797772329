import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES } from '$business/enums';

import Menu from '../../sideMenu';

import AppOutlet from '$product/_init/appOutlet';
// import MainHeader from '../../mainHeader';
import { getFactoryLogo } from '$business/helpers/utils';
import { IonReactRouter } from '@ionic/react-router';
import CurrentStateModel, { initialCurrentState } from '$business/models/currentState';
import UserModel from '$business/models/user';
import PATH from '$business/enums/paths';
import { InitModel } from '$business/redux/init/types';

interface RouterProps {
  isLoggedIn: boolean;
  currentRoute: string;
  onLogout: Function;
  init: InitModel;
  deviceId: string;
  user?: UserModel;
}

const Router: React.FC<RouterProps> = ({ isLoggedIn, init, currentRoute, deviceId, user, onLogout }) => {
  const redirectRoute = ROUTES.DEFAULT_APP_PATH;

  const currentState: CurrentStateModel = {
    ...initialCurrentState,
    isLoggedIn,
    isOnboarding: !deviceId,
    user,
    groups: init.groups,
    isLeader: init.isLeader,
    defaultPath: !deviceId ? PATH.ONBOARDING : '',
  };

  return (
    <IonReactRouter basename={process.env.REACT_APP_PUBLIC_URL}>
      <Menu
        currentRoute={currentRoute}
        onLogout={onLogout}
        currentState={currentState}
        isLoggedIn={isLoggedIn}
        menuId="start"
        user={user}
        logo={getFactoryLogo()}
      />
      {/* <MainHeader currentState={currentState} logo={getFactoryLogo()} /> */}
      <IonContent>
        <IonContent>
          <AppOutlet isLoggedIn={isLoggedIn} currentState={currentState} user={user} />
          <Route path="/" render={() => <Redirect to={redirectRoute} />} exact />
        </IonContent>
      </IonContent>
    </IonReactRouter>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.localStorage.accessToken !== '' && state.localStorage.userId,
  deviceId: state.localStorage.deviceId,
  init: state.init,
  currentRoute: state.routing.currentRoute,
  user: state.currentUser,
});

const mapDispatchToProps = {
  onLogout: authActions.logout,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Router);
