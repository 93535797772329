import React from 'react';
import { IonFooter } from '@ionic/react';
import { Flex } from '$gstyles/wrapper';
import { SPACE } from '$gstyles';

interface FooterProps {
  children: React.ReactNode;
  className?: string;
  justifyContent?: string;
}

const Footer: React.FC<FooterProps> = ({ children, className = '', justifyContent = 'space-between' }) => {
  return (
    <IonFooter className={className}>
      <Flex padding={SPACE.MEDIUM} justifyContent={justifyContent}>
        {children}
      </Flex>
    </IonFooter>
  );
};

export default Footer;
