import { configs } from '$configs';

export const getFactoryLogo = () => {
  const logoPath = configs.display.logoPath;
  return `${logoPath}`;
};

export const validatePassword = (value) => {
  if (!value) return true;
  if (value.length < 4) return false;
  return true;
};
