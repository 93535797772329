import * as i18n from 'react-intl-universal';
import { Text } from '$gcomponents/primitives';

export const checkKeyFormat = (key) => {
  if (!key) return false;
  if (key.includes(' ') || key !== key.toUpperCase()) return false;
  return true;
};

const intl = (key, param: any = undefined): string => {
  if (!checkKeyFormat(key)) return key;

  if (param) return i18n.get(key || 'EMPTY', param) || key;

  const value = i18n.get(key || 'EMPTY') || key;
  return typeof value === 'string' ? value : key;
};

export { Text };

export default intl;
