import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, SPACE } from '$gstyles';

export const OnboardScreenWrapper = styled(IonPage)`
  .consent {
    align-items: flex-start;
    .MuiCheckbox-root {
      padding-top: 0;
    }
    .MuiTypography-root {
      font-size: ${FONT.SMALL};
      text-align: left;
    }
  }
`;

export const LoginScreenWrapper = styled(IonPage)`
  .app-version {
    display: flex;
    justify-content: center;
  }
`;

export const ConfirmScreenWrapper = styled(IonPage)`
  .code-input {
    width: 320px;
    margin: ${SPACE.XLARGE} auto;

    input {
      width: 45px;
      height: 45px;
      padding: 0;
      font-size: 24px;
      text-align: center;
      margin-right: 8px;
      text-transform: uppercase;
      color: #494949;
      font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif;
      border: 1px solid #d6d6d6;
      border-radius: 4px;
      background: #fff;
      background-clip: padding-box;
    }
  }

  .large {
    font-size: 1.2em;
  }
`;
