import styled from 'styled-components';
import { Div, Mixins, WEIGHT } from '$gstyles';

export const ImagePlaceholder = styled(Div)`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--ion-border-color);
  ${p => {
    const imageSize = parseInt(p.width) || parseInt(p.height);
    const borderSize = Math.min(imageSize / 12, 10);
    const fontSize = Math.min(imageSize / 6, 28);
    return `
      border-width: ${borderSize}px;
      padding: ${imageSize / 10}px;
      > * {
        text-align: center;
        text-transform: uppercase;
        font-size: ${fontSize}px;
        opacity: 0.4;
        font-weight: ${WEIGHT.SEMI_BOLD};
      }
    `;
  }}
`;

export const ImageWrapper = styled(Div)`
  ${Mixins.background}
  background-color: transparent;
  background-position: ${p => p.position || '50%'};
  background-size: ${p => (p.size === 'contain' ? (p.size === 'stretch' ? '' : 'contain') : 'cover')};
  ${p => p.size === 'stretch' && 'background-size: 100% 100%;'}
  background-image: url(${p => p.src});
  width: 100%;
  height: 100% !important;
  ${p => {
    const s = p.shadowSize;
    return p.showShadow && `box-shadow: ${s}px ${s}px ${s * 2}px #999;`;
  }};
`;
