import React from 'react';
import intl from 'react-intl-universal';

interface TextProps {
  k?: string;
  p?: any;
  children?: any;
}

const Text: React.FC<TextProps> = ({ k, p, children }) => {
  if (k) {
    if (!p) return <>{intl.get(k)}</>;
    return <>{intl.get(k, p)}</>;
  }
  return <>{children}</>;
};

export default Text;
