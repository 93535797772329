import MemberModel, { deriveRawToMember } from './member';

export default interface AttendanceModel extends MemberModel {
  attended: number;
  favorite: number;
  settings: string;
}

export const deriveRawToAttendance = (raw) => {
  return {
    ...deriveRawToMember(raw),
    attended: raw.attended,
    favorite: raw.favorite || 0,
    settings: raw.settings || {},
  };
};
