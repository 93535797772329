import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import intl, { Text } from '$gintl';
import PATH from '$business/enums/paths';
import { Header, LoginForm } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { Box, SPACE, Space } from '$gstyles';
import { LogoHeader } from '$gcomponents/reusables';
import { Link } from '$gcomponents/primitives';
import { features } from '$configs';
import LOGIN_FORM from './loginForm';
import { LoginScreenWrapper } from './styles';
import { initActions } from '$business/redux/init';

interface LoginScreenProps {
  login: Function;
  history: any;
  onLogin?: Function;
}

const LoginScreen: React.FC<LoginScreenProps> = ({ login, history, onLogin }) => {
  const onSubmit = (values) => {
    const param = {
      ...values,
      email: process.env.REACT_APP_ACCOUNT_EMAIL,
      remember: true,
    };
    if (onLogin) {
      onLogin(param, true);
      return;
    }
    login(param, true).then((result) => {
      setTimeout(() => {
        if (result === 'PENDING') history.push(PATH.VERIFY);
      }, 500);
    });
  };

  return (
    <LoginScreenWrapper id="login-screen">
      <Header title="SCREEN.LOGIN.TITLE" />
      <IonContent>
        <Box.LARGE>
          <LogoHeader />
          <LoginForm onSubmit={onSubmit} loginForm={LOGIN_FORM} />
          <Space.Ver margin={SPACE.LARGE}>
            <div>
              <Link route={PATH.FORGOT} button>
                {intl('MESSAGE.FORGOT_LINK')}
              </Link>
            </div>
            <div>
              <Link route={PATH.ONBOARDING} button>
                {intl('MESSAGE.REGISTER_LINK')}
              </Link>
            </div>
            {!features.disableRegister && (
              <div>
                <Link route={PATH.ONBOARDING} button>
                  <Text k="SCREEN.LOGIN.REGISTER_LINK" />
                </Link>
              </div>
            )}
          </Space.Ver>
        </Box.LARGE>
      </IonContent>
    </LoginScreenWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
});

const mapDispatchToProps = {
  login: initActions.login,
};

const connected: any = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(LoginScreen));
