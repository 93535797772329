import { Dispatch } from 'redux';

import { LOADED, LOADING } from '$gbusiness/redux/loading/types';
import { LOAD_STORAGE } from '$gbusiness/redux/localStorage/types';
import { apiService, localStorage, theme } from '$gbusiness/services';

import {
  INIT_SUCCESS,
  INIT_LOGIN_SUCCESS,
  LOAD_HEADERS,
  PHONE_VERIFIED_FAILURE,
  USER_EXISTS_SUCCESS,
  PHONE_VERIFIED_SUCCESS,
  CODE_VERIFIED_SUCCESS,
  MESSAGE_SUCCESS,
  SUBMITTED_SUCCESS,
  SET_MYGROUPS,
} from './types';
import { configs } from '$configs';
import { SET_CUSER } from '$gbusiness/redux/currentUser/types';
import UserModel, { defaultUser } from '$business/models/user';
// import { handleApiFail } from '$gbusiness/services/api';

import { AppModel } from '../';
import { addMinutesToTimestamp, sleep } from '$gbusiness/helpers/util';
import { LOGGING_IN, LOGIN_FAILURE, PROCESSING } from '$gbusiness/redux/auth/types';
import { handleFail } from '$gbusiness/redux/auth/actions';
import { Storage } from '@capacitor/storage';
import { handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToUser } from '../../models/user';
import { toastDanger } from '$gbusiness/redux/toaster/actions';
import intl from '$gintl';

function restoreTheme(localTheme) {
  theme.switchTheme(localTheme);
}

async function loadStorage(dispatch) {
  const storageData = await getStorageData();

  await dispatch({
    type: LOAD_STORAGE,
    localStorage: {
      ...storageData,
    },
  });

  const { accessToken: authtoken, deviceId, userId } = storageData;
  await dispatch({
    type: LOAD_HEADERS,
    headers: {
      authtoken,
      deviceId,
      userId,
    },
  });
}

export const getStorageData = async (): Promise<any> => {
  const response = await Promise.all(configs.localStorage.map((k) => Storage.get({ key: k })));

  const obj = configs.localStorage.reduce((acc, v, i) => {
    acc[v] = response[i].value || '';
    return acc;
  }, {});

  return obj;
};

async function authToken(dispatch) {
  const response = await apiService.fetchApi({
    url: configs.api.token,
    param: {},
    method: 'POST',
  });

  if (!response || !response?.user?.id) {
    localStorage.clearAuth();
    return;
  }

  const user: UserModel = deriveRawToUser(response.user) || defaultUser;
  await storeAuth(response, user);

  dispatch({
    type: SET_CUSER,
    user,
  });

  dispatch({
    type: SET_MYGROUPS,
    user,
    absents: response.absents,
    worships: response.worships,
  });
}

export function loadApp(): any {
  return async (dispatch: Dispatch, getState: () => AppModel) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.INITIALIZING',
    });

    await loadStorage(dispatch);

    // Load Theme
    const { localStorage } = getState();

    restoreTheme(localStorage.theme);

    await authToken(dispatch);

    dispatch({
      type: INIT_SUCCESS,
    });
  };
}

export const storeAuth = async (response, user) => {
  const authObj = {
    ...(response.accessToken && { accessToken: response.accessToken }),
    ...(response.deviceId && { deviceId: response.deviceId }),
    ...(response.expiredAt && { expTimestp: addMinutesToTimestamp(response.expiredAt) }),
    ...(user && { userId: user.userId }),
  };

  await localStorage.setStorageItems(authObj);
};

export function login(param: any, isFormData = false): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({
      type: LOGGING_IN,
      loadingText: 'PROGRESS.LOGGING_IN',
    });

    const response = await apiService.fetchApi({
      url: configs.api.login,
      ...(isFormData ? { formData: param } : { param }),
      isPublic: true,
      // mockData: loginMock,
    });

    if (!response || !response.success) {
      handleFail(dispatch, response?.message, 'MESSAGE.LOGIN_FAIL', 'large');
      return;
    }

    if (response.status === 'PENDING') {
      dispatch({ type: LOADED });
      await localStorage.setStorageItem('phone', param?.phone);
      await dispatch(resendCode(param.phone));

      return Promise.resolve('PENDING');
    }
    if (response.status === 'WAITING') {
      dispatch({ type: LOADED });
      handleFail(dispatch, response?.message, 'MESSAGE.LOGIN_FAIL', 'large');
      return;
    }

    const user: UserModel = deriveRawToUser(response.user) || defaultUser;
    await storeAuth(response, user);

    await dispatch({
      type: INIT_LOGIN_SUCCESS,
      accessToken: response.accessToken,
      deviceId: response.deviceId,
      userId: response.user.userId,
      user,
    });

    await dispatch({
      type: SET_CUSER,
      user,
    });

    dispatch({
      type: SET_MYGROUPS,
      user,
      absents: response.absents,
      worships: response.worships,
    });

    return Promise.resolve('ACTIVE');
  };
}

export function verifyPhone(param, isFormData = false): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await apiService.fetchApi({
      url: configs.api.verifyPhone,
      ...(isFormData ? { formData: param } : { param }),
      isPublic: true,
    });

    // Server Error. Network or whatever
    if (!response || !response.success || response.err) {
      handleFail(dispatch, response?.message, 'ERROR.SERVER', 'large');
      return;
    }

    if (response.deviceId) await localStorage.setStorageItem('deviceId', response.deviceId);

    await localStorage.setStorageItem('phone', param.phone);

    // Brand new person. Phone number not found in Antioch
    if (response && response.success === 2) {
      await localStorage.setStorageItem('status', 'WAITING');
      dispatch({ type: PHONE_VERIFIED_FAILURE });
      return;
    }

    if (response && response.success) {
      // User already is a registered and verified app user
      if (response.user) {
        await localStorage.setStorageItem('status', 'ACTIVE');
        dispatch({ type: USER_EXISTS_SUCCESS });
        return;
      }
      // User exists in Antioch DB and needs to verify phone
      await localStorage.setStorageItem('status', 'PENDING');
      dispatch({ type: PHONE_VERIFIED_SUCCESS, action: param?.phone });
    }
  };
}

export function resendCode(phone, isFormData = false): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await apiService.fetchApi({
      url: configs.api.resendCode,
      ...(isFormData ? { formData: { phone } } : { param: { phone } }),
      isPublic: true,
    });

    // Server Error. Network or whatever
    if (!response || !response.success || response.err) {
      handleFail(dispatch, response?.message, 'ERROR.SERVER', 'large');
      return;
    }
    handleApiSuccess(dispatch, LOADED, intl('MESSAGE.CODE_SENT'), 'large');
  };
}

export function verifyCode(phone, code, isFormData = false): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await apiService.fetchApi({
      url: configs.api.verifyCode,
      ...(isFormData ? { formData: { phone, code } } : { param: { phone, code } }),
      isPublic: true,
    });

    // Server Error. Network or whatever
    if (!response || !response.success || response.err) {
      handleFail(dispatch, response?.message, 'ERROR.CODE_VERIFY', 'large');
      return;
    }

    const user: UserModel = deriveRawToUser(response.user) || defaultUser;
    await storeAuth(response, user);

    await dispatch({
      type: INIT_LOGIN_SUCCESS,
      accessToken: response.accessToken,
      deviceId: response.deviceId,
      userId: response.user.userId,
      user,
    });

    // Brand new person. Phone number not found in Antioch
    if (response && response.success) {
      dispatch({
        type: CODE_VERIFIED_SUCCESS,
      });
    }
  };
}

export function submitApproval(param, isFormData = false): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await apiService.fetchApi({
      url: configs.api.submitApproval,
      ...(isFormData ? { formData: param } : { param }),
      isPublic: true,
    });

    // Server Error. Network or whatever
    if (!response || !response.success || response.err) {
      handleFail(dispatch, response?.message, 'ERROR.SERVER', 'large');
      return;
    }

    dispatch({
      type: SUBMITTED_SUCCESS,
    });

    return Promise.resolve(true);
  };
}

export function approveUser(user, approve, isFormData = false): any {
  return async (dispatch: Dispatch<any>, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const param = { ...user, approve };

    const response = await apiService.fetchApi({
      url: configs.api.approveUser,
      ...(isFormData ? { formData: param } : { param }),
      isPublic: true,
    });

    // Server Error. Network or whatever
    if (!response || !response.success || response.err) {
      handleFail(dispatch, response?.message, 'ERROR.SERVER', 'large');
      return;
    }

    dispatch({
      type: SUBMITTED_SUCCESS,
    });

    return Promise.resolve(true);
  };
}

export function requestPasswordReset(phone): any {
  return async (dispatch: Dispatch, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await apiService.fetchApi({
      url: configs.api.forgotPassword,
      param: { phone },
      isPublic: true,
    });

    if (!response || !response.success) {
      dispatch({
        type: LOGIN_FAILURE,
        err: 'ERROR.NETWORK',
      });

      dispatch(toastDanger({ text: response.err, key: 'ERROR.SERVER' }));
      return;
    }

    handleApiSuccess(dispatch, LOADED, intl('MESSAGE.FORGOT_MESSAGE'), 'large');
  };
}

export function deleteMyAccount(): any {
  return async (dispatch: Dispatch, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await apiService.fetchApi({
      url: configs.api.users.delete,
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.message, 'ERROR.SERVER', 'large');
      return;
    }

    await sleep(1000);
    handleApiSuccess(dispatch, LOADED, intl('MESSAGE.ACCOUNT_DELETED'), 'large');

    await sleep(500);
    localStorage.clearAuth();

    // window.location.href = '/';
  };
}

export function contactUs(param): any {
  return async (dispatch: Dispatch, getState) => {
    dispatch({
      type: PROCESSING,
      loadingText: 'PROGRESS.SUBMITTING',
    });

    const response = await apiService.fetchApi({
      url: configs.api.support,
      param,
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleFail(dispatch, response?.message, 'ERROR.SERVER', 'large');
    }

    handleApiSuccess(dispatch, LOADED, intl('MESSAGE.SUPPORT_MESSAGE'), 'large');
    return Promise.resolve(true);
  };
}

export function displayMessage(message) {
  return { type: MESSAGE_SUCCESS, message };
}
