import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonAlert, IonContent, useIonViewDidEnter } from '@ionic/react';
import intl, { Text } from '$gintl';
import AuthCode from 'react-auth-code-input';

import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { LogoHeader } from '$gcomponents/reusables';
import { Box, SPACE, Space } from '$gstyles';
import PATH from '$business/enums/paths';

import { Button, Link } from '$gcomponents/primitives';
import { initActions } from '$business/redux/init';
import { ConfirmScreenWrapper } from './styles';
import { ACTIVE } from '$business/enums/onboardingStatus';
import { DEFAULT_APP_PATH } from '$business/enums/routes';

interface ConfirmCodeScreenProps {
  verify: Function;
  resend: Function;
  phone: string;
  screenState: any;
  status: string;
  history: any;
}

const ConfirmCodeScreen: React.FC<ConfirmCodeScreenProps> = ({
  verify,
  status,
  resend,
  phone,
  screenState,
  history,
}) => {
  const [code, setCode] = useState('');

  useIonViewDidEnter(() => {
    if (!phone) history.replace('/');
  });

  useEffect(() => {
    if (status === ACTIVE) history.replace(DEFAULT_APP_PATH);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onChangeCode = (str) => {
    setCode(str);
  };

  const onResend = () => {
    if (phone) resend(phone);
  };

  const onSubmit = (values) => verify(phone, code);

  return (
    <ConfirmScreenWrapper id="confirm-code-screen">
      <Header title="SCREEN.ONBOARDING.TITLE" />
      <IonContent>
        <IonAlert
          isOpen={screenState !== ''}
          header={intl('MESSAGE.SUBMITTED')}
          message={intl('MESSAGE.PASSWORD_RESET')}
          onDidDismiss={() => {
            history.goBack();
          }}
          buttons={['OK']}
        />
        <Box.LARGE className="center">
          <LogoHeader />
          <div className="center">{intl('SCREEN.CODE.MESSAGE')}</div>
          <AuthCode containerClassName="code-input" allowedCharacters="numeric" onChange={onChangeCode} />
          <Button onClick={onSubmit} className="center">
            {intl('BUTTON.CONFIRM')}
          </Button>
          {phone && (
            <Space.Ver margin={SPACE.LARGE}>
              <Link className="link" onClick={onResend} button>
                {intl('SCREEN.CODE.RESEND', { phone })}
              </Link>
            </Space.Ver>
          )}
          <Space.Ver margin={SPACE.LARGE}>
            <Link route={PATH.LOGIN} routerDirection="back" button>
              <Text k="SCREEN.ONBOARD.LOGIN_MESSAGE" />
            </Link>
          </Space.Ver>
        </Box.LARGE>
      </IonContent>
    </ConfirmScreenWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  status: state.init.status,
  phone: state.localStorage.phone,
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  verify: initActions.verifyCode,
  resend: initActions.resendCode,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ConfirmCodeScreen));
