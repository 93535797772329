import React, { useState } from 'react';
import { List, ListItem, ListSubheader } from '@mui/material';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import AbsentModal from './absentModal';
import { phoneIcon, profileAvatar } from '../groupScreen/utils';

interface AttendanceListProps {
  users: Array<any>;
  handleAttend: Function;
  handleAbsent: Function;
  className?: string;
  absents: Array<any>;
  worships: Array<any>;
}

const AttendanceList: React.FC<AttendanceListProps> = ({
  users,
  className = '',
  handleAttend,
  handleAbsent,
  absents,
}) => {
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const onConfirmAbsent = (id, reason, note) => {
    setSelectedUser(null);
    handleAbsent(id, reason, note);
  };

  const renderAttendInput = (user, newValue) => {
    const attended = user?.attended;

    return (
      <div className="checkbox absent">
        {attended === newValue ? (
          <CheckCircle color="primary" onClick={() => handleAttend(user, newValue)} />
        ) : (
          <RadioButtonUnchecked color="primary" onClick={() => handleAttend(user, newValue)} />
        )}
      </div>
    );
  };

  const renderAbsentInput = (user) => {
    const attended = user?.attended;
    const absent = (absents || []).find((w) => w.value === user?.settings?.reason);

    return (
      <div className="checkbox absent">
        {attended === 0 ? (
          <div className="absent-section">
            <CheckCircle color="primary" onClick={() => setSelectedUser(user)} />
            {attended === 0 && user?.settings && <div className="reason">{absent?.label}</div>}
          </div>
        ) : (
          <RadioButtonUnchecked color="primary" onClick={() => setSelectedUser(user)} />
        )}
      </div>
    );
  };

  return (
    <div className={`table ${className}`}>
      <ListSubheader className="list-row primary filled">
        <div className="info">성도</div>
        <div className="option">1부</div>
        <div className="option">2부</div>
        <div className="option">3부</div>
        <div className="option">온라인</div>
        <div className="option">불참</div>
      </ListSubheader>
      <List>
        {(users || []).map((user, i) => (
          <ListItem className="list-row" key={i} style={{ height: '80px' }}>
            <div className="info">
              <div className="photo">{profileAvatar(user, 48, true)}</div>
              <div className="phone">{phoneIcon(user?.phone)}</div>
              <div className="name">{user.name}</div>
            </div>
            <div className="option">{renderAttendInput(user, 1)}</div>
            <div className="option">{renderAttendInput(user, 2)}</div>
            <div className="option">{renderAttendInput(user, 3)}</div>
            <div className="option">{renderAttendInput(user, -1)}</div>
            <div className="option">{renderAbsentInput(user)}</div>
          </ListItem>
        ))}
      </List>
      <AbsentModal
        user={selectedUser}
        absents={absents}
        onClose={() => setSelectedUser(null)}
        onConfirm={onConfirmAbsent}
      />
    </div>
  );
};

export default AttendanceList;
