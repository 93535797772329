import React from 'react';
import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { dateToString, format } from '$gbusiness/helpers/date';
import NativeSelect from '$gcomponents/primitives/selectInput/nativeSelect';
import { intToArray } from '$gbusiness/helpers/util';
import { subWeeks } from 'date-fns';
import { DATE_FORMATS } from '$gbusiness/enums';
import ABSENT_OPTIONS from '$business/enums/absentOptions';
import { input } from '$gbusiness/helpers';
import WORSHIP_OPTIONS from '$business/enums/worshipOptions';

interface AttendanceFilterProps {
  onChangeFilter: Function;
  filter: any;
}

export const getLastSunday = (t: any = new Date()) => {
  t.setDate(t.getDate() - t.getDay());
  return dateToString(t);
};

const AttendanceFilter: React.FC<AttendanceFilterProps> = ({ filter, onChangeFilter }) => {
  const { attendDate } = filter;

  const onChange = (t) => {
    onChangeFilter({ attendDate: t });
  };

  const buildOptions: any = intToArray(4).reduce<any[]>((acc, i) => {
    const weeksAgo: any = subWeeks(new Date(), i);
    const sunday = getLastSunday(weeksAgo);
    const obj = {
      labelText: format(sunday, DATE_FORMATS.DATE_WEEKDAY),
      value: sunday,
    };
    acc.push(obj);
    return acc;
  }, []);

  return (
    <NativeSelect
      label="SCREEN.ATTENDANCE.FILTER_LABEL"
      value={attendDate}
      className="attend-date-select"
      onChange={onChange}
      variant="outlined"
      size="small"
      isNumeric={false}
      options={buildOptions}
    />
  );
};

export const ABSENT_FORM = (absents): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.RADIO,
        label: '',
        value: 'reason',
        options: absents?.length
          ? absents.map((a) => ({
              labelText: a.label,
              value: a.value,
            }))
          : ABSENT_OPTIONS,
        validation: [input.requiredValidation],
        gridSize: 12,
      },
      {
        input: INPUT.TEXTAREA,
        rows: 3,
        label: 'SCREEN.ATTENDANCE.COLS.MEMO',
        value: 'note',
        gridSize: 6,
      },
    ],
  },
];

export const WORSHIP_FORM = (worships): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.RADIO,
        label: '',
        value: 'attended',
        options: worships?.length
          ? worships.map((a) => ({
              labelText: a.label,
              value: a.value,
            }))
          : WORSHIP_OPTIONS,
        validation: [input.requiredValidation],
        gridSize: 12,
      },
    ],
  },
];

export default AttendanceFilter;
