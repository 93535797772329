import { combineReducers } from 'redux';

import { loadingReducer } from '$gbusiness/redux/loading';
import { routerReducer } from '$gbusiness/redux/router';
import { cuserReducer } from '$gbusiness/redux/currentUser';
import { userReducer } from '$gbusiness/redux/user';
import { toasterReducer } from '$gbusiness/redux/toaster';
import { localStorageReducer } from '$gbusiness/redux/localStorage';

import { initReducer } from './init';
import { directoryReducer } from './directory';

const appReducer = combineReducers({
  init: initReducer,
  loading: loadingReducer,
  localStorage: localStorageReducer,
  routing: routerReducer,
  currentUser: cuserReducer,
  user: userReducer,
  toaster: toasterReducer,
  directory: directoryReducer,
});

export default appReducer;
