import { dialog } from '$gcomponents/reusables';
import intl, { checkKeyFormat } from '$gintl';
import { SORT } from '../enums';
import { AMEX, DISCOVER, MASTERCARD, VISA } from '../enums/cardTypes';
import { ASC, DSC } from '../enums/sort';
import { SideMenuModel } from '../models/sideMenu';
import { getStorageObj } from '../services/local.storage';
import { getEl } from './input';

export const clog = (...items) => {
  if (process.env.REACT_APP_ENV !== 'prod') {
    console.log(...items);
  }
};

export const mergeObjectStrict = (obj1 = {}, obj2 = {}) => {
  const obj = { ...obj1 };
  for (const key in obj) {
    if (obj2[key] !== undefined) obj[key] = obj2[key];
  }
  return obj;
};

export const itemText = (action: string, item: string) => {
  return intl('ITEM.' + action.toUpperCase(), {
    item: checkKeyFormat(item) ? intl('COMMON.' + item.toUpperCase()) : item,
  });
};

export const leadingZero = (number, digits) => {
  const zeros = Array(digits).fill(0).join('');
  return (zeros + number).slice(-4);
};

export const accessString = (accessLevel, feature = '') => {
  const accessClass =
    accessLevel === undefined || accessLevel === null ? '' : `access_${accessLevel.toString()}`;
  const featureClass = feature ? `feature_${feature}` : '';
  return `${accessClass} ${featureClass}`;
};

export const canAccess = (accessLevel, userLevel) => {
  if (accessLevel === undefined || accessLevel === null) return true;
  if (accessLevel === 0 || accessLevel) {
    if (userLevel <= accessLevel) return true;
  }
  return false;
};

export const hasFeature = async (feature) => {
  const localFeatures = await getStorageObj('features');
  // const appFeature = document.body.classList.contains(`body_${feature}`);
  console.log('LOCAL FEATURES', localFeatures[feature]);
  if (localFeatures[feature]) return true;
  return false;
};

export const toFileSize = (number) => {
  if (isNaN(number) || number == null) return '';
  if (number >= 1000000000000) return `${(number / 100000000000).toFixed(0)} TB`;
  if (number >= 1000000000) return `${(number / 100000000).toFixed(0)} GB`;
  if (number >= 1000000) return `${(number / 1000000).toFixed(0)} MB`;
  if (number >= 1000) return `${(number / 1000).toFixed(0)} KB`;
  return `${number} B`;
};

export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export const changeSort = (order) => (order === DSC ? ASC : DSC);

export const intToArray = (number) =>
  Array(number)
    .fill(1)
    .map((v, i) => i);

export const rangeToArray = (start, end) => {
  if (start > end) return [];
  const diff = end - start + 1;
  return Array(diff)
    .fill(1)
    .map((v, i) => start + i);
};

export const flattenObject = (obj) => {
  return Object.assign(
    {},
    ...(function _flatten(o) {
      return [].concat(
        ...Object.keys(o).map((k) => (typeof o[k] === 'object' ? _flatten(o[k]) : { [k]: o[k] })),
      );
    })(obj),
  );
};

export const popPlaceholder = (text = '') => {
  dialog.alert({
    title: 'MESSAGE.SORRY',
    message: 'MESSAGE.FEATURE_NOT_READY',
    ...(text && { text }),
    key: { name: 'Supercalifrgerlisticexplialidocous' },
  });
};

export const sortArray = (data, key, order) => {
  const temp = [...data];
  temp.sort((a, b) =>
    order === SORT.ASC
      ? a[key] > b[key]
        ? 1
        : b[key] > a[key]
        ? -1
        : 0
      : a[key] < b[key]
      ? 1
      : b[key] < a[key]
      ? -1
      : 0,
  );
  return temp;
};

export const mirrorObjKeys = (obj) => {
  const newObj = {};
  for (let i in obj) {
    newObj[i] = i;
  }
  return newObj;
};

export const getStyleColor = (color, colorType = '') => {
  return `var(--ion-color-${color}${colorType ? `-${colorType}` : ''})`;
};

export const addMinutesToTimestamp = (seconds, timestamp = 0) => {
  const now = timestamp || +new Date();

  return now + seconds * 1000;
};

export const getLast4 = (str) => {
  if (!str) return '';
  if (str.length > 4) return str.toString().substr(-4);
  return str;
};

export const getCardType = (str) => {
  if (!str) return '';
  switch (str[0] || '') {
    case '3':
      return AMEX;
    case '4':
      return VISA;
    case '5':
      return MASTERCARD;
    case '6':
      return DISCOVER;
    default:
      return '';
  }
};

export const randomInt = (min, max) => {
  return parseInt(Math.floor(Math.random() * (max - min + 1)) + min);
};

export const createUuid = () => {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const percentage = (amount, decimal = 1) => Number(amount).toFixed(decimal) + '%';

export const currency = (amount, shouldRound = false) => intl('PRICE', { amount: amount || 0 });

export const dollar = (amount, useDecimal = true) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    ...(!useDecimal && { maximumFractionDigits: 0 }),
  });

  return formatter.format(amount);
};

export const currencyAuto = (amount, thres = 3) => {
  return amount % 1 === 0 && amount > 10 ** thres ? dollar(amount, false) : currency(amount);
};

export const getCardPathFromType = (cardType) => {
  return `/assets/img/${cardType.toLowerCase()}_icon.png`;
};

export const getDefaultCardId = (cards) => {
  const card = cards.find((c) => c.isDefault);
  if (!card) return 0;
  return card.cardId;
};

export const getCardPath = (cardNumber) => {
  let fileName;
  switch (cardNumber[0] || '') {
    case '3':
      fileName = 'amex';
      break;
    case '4':
      fileName = 'visa';
      break;
    case '5':
      fileName = 'mastercard';
      break;
    case '6':
      fileName = 'discover';
      break;
    default:
      fileName = 'card';
      break;
  }
  return `/assets/img/${fileName}_icon.png`;
};

export const parseAddress = (add) => {
  if (!add || !add?.length) return '';

  const tmp = add.split(',');
  let street;
  if (tmp.length <= 1) return add;

  street = tmp[0];
  if (tmp.length === 2) {
    return {
      street,
      zip: tmp[1],
    };
  }
  if (tmp.length > 2) {
    const tmp2 = tmp[2].trim().split(' ');
    return {
      street,
      city: tmp[1],
      state: tmp2[0],
      zip: tmp2[1],
    };
  }

  return add;
};

export const roundDecimal = (num, decimal = 2) => {
  const multiplier = 10 ** decimal;
  return Math.round(num * multiplier) / multiplier;
};

export const floorDecimal = (num, decimal = 2) => {
  const multiplier = 10 ** decimal;
  return Math.floor(num * multiplier) / multiplier;
};

export const toNumber = (str) => {
  if (!str) return 0;
  return Math.round(Number(str.toString().replace(/,/g, '')) * 100) / 100;
};

export const joinAddress = (address) => {
  if (!address) return '';
  const { city, state, zip } = address;
  if (city && state) return `${city}, ${state} ${zip}`;
  return zip;
};

export const formatAddress = (address, delim = '<br>') => {
  if (!address) return address;
  return address.replace(', ', delim);
};

export const replaceAll = (str, mapObj) => {
  var re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(re, function (matched) {
    return mapObj[matched.toLowerCase()];
  });
};

export const getPublicFactoryId = () => {
  return process.env.REACT_APP_FACTORY_ID;
};

export const generateGetParam = (initialParam) => {
  const factoryId = getPublicFactoryId();
  const param = {
    ...(!initialParam ? {} : initialParam),
    ...(factoryId && { factoryId }),
  };
  return new URLSearchParams(param).toString();
};

export const focusInputEl = async (selector, timeout) => {
  await sleep(timeout);
  const el: any = getEl(selector);
  if (el) {
    el.select();
    el.focus();
  }
};

export const onPressEnter = (e, value, onEnter) => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode === 13 && onEnter) {
    onEnter(value);
  }
};

export const getSideMenu = (route): SideMenuModel => {
  return {
    title: route.title,
    ...(route.icon && { icon: route.icon }),
    ...(isUrl(route.path)
      ? { href: route.path }
      : route.path && {
          path: `${route.path}${route.defaultId ? '/' + route.defaultId : ''}`,
        }),
    ...(route.component && { route: route }),
    ...(route.routes && {
      submenu: route.routes.map((r) => getSideMenu(r)),
    }),
  };
};

export const isUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const filterObjProps = (obj, props) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (props.includes(key)) acc[key] = obj[key];
    return acc;
  }, {});
};

export const download = (path, filename) => {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
};

export const removeProp = (originalObj, propName = undefined) => {
  const obj = { ...originalObj };
  for (const key in obj) {
    if (obj[key] === propName) {
      delete obj[key];
    }
  }
  return obj;
};

export const pickRandomIndex = (arr, isAdvanced = false) => {
  if (!arr || !arr.length) return -1;
  if (!isAdvanced) {
    return randomInt(0, arr.length - 1);
  }
  const rnum = randomInt(0, 100);
  let temp = 0;
  const defaultWeight = Math.floor(100 / arr.length);

  const index = arr.findIndex((item) => {
    const weight = item.weight || defaultWeight;
    if (weight + temp >= rnum) return true;
    temp += weight;
    return false;
  });
  if (index < 0) return 0;
  return index;
};

export const pickRandomArr = (arr, isAdvanced = false) => {
  const index = pickRandomIndex(arr, isAdvanced);
  if (index < 0) return undefined;
  return arr[index];
};

export const printPage = (selector) => {
  let mywindow = window.open('', 'PRINT', 'height=400,width=600');

  if (!mywindow) return;

  const el: any = getEl(selector);

  mywindow.document.write('<html><head><title>' + document.title + '</title>');
  mywindow.document.write('</head><body>');
  mywindow.document.write(el.innerHTML);
  mywindow.document.write('</body></html>');

  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
};
