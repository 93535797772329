import { Storage } from '@capacitor/storage';
import ReduxStore from '$business/redux';

import { configs } from '$configs';
import { addMinutesToTimestamp } from '$gbusiness/helpers/util';
import { SET_ITEMS, SET_ITEM } from '$gbusiness/redux/localStorage/types';

export const getStorageData = async (): Promise<any> => {
  const response = await Promise.all(configs.localStorage.map((k) => Storage.get({ key: k })));

  const obj = configs.localStorage.reduce((acc, v, i) => {
    if (v.includes('Id') || v === 'expTimestp') acc[v] = parseInt(response[i].value || '0');
    else acc[v] = response[i].value || '';
    return acc;
  }, {});

  return obj;
};

// Should not be used by consumer. Use Redux store to get the localStorage Item
export const getStorageItem = async (key: string) => {
  const item = await Storage.get({ key });

  return item?.value;
};

// Should not be used by consumer. Use Redux store to get the localStorage Item
export const getStorageObj = async (key: any) => {
  const objStr = await Storage.get({ key });
  return JSON.parse(objStr.value || '[]');
};

export const setStorageObj = async (key: string, item: any, origin = '') => {
  if (item == null) return;

  const objStr = JSON.stringify(item || []);
  await Storage.set({ key, value: objStr });

  ReduxStore.dispatch({
    type: SET_ITEM + (origin && '_' + origin),
    origin,
    payload: {
      [key]: item || [],
    },
  });
};

export const setStorageItem = async (key: string, item: string) => {
  if (item == null) return;
  await Storage.set({ key, value: item });
  ReduxStore.dispatch({
    type: SET_ITEM,
    payload: {
      [key]: item,
    },
  });
};

export const setStorageItems = async (obj: object) => {
  await Promise.all(
    Object.entries(obj).map(([key, value]) => {
      if (value == null) return undefined;

      return Storage.set({ key, value });
    }),
  );

  ReduxStore.dispatch({
    type: SET_ITEMS,
    payload: obj,
  });
};

export const setExpTimestp = async (minutes) => {
  const expTimestp = addMinutesToTimestamp(minutes);

  await Storage.set({ key: 'expTimestp', value: expTimestp.toString() });
};

export const clearAuth = async () => {
  if (configs.localStorage.includes('expTimestp')) await Storage.remove({ key: 'expTimestp' });
  if (configs.localStorage.includes('accessToken')) await Storage.remove({ key: 'accessToken' });
  if (configs.localStorage.includes('refreshToken')) await Storage.remove({ key: 'refreshToken' });
  if (configs.localStorage.includes('userId')) await Storage.remove({ key: 'userId' });

  ReduxStore.dispatch({
    type: SET_ITEMS,
    payload: {
      userId: 0,
      expTimestp: 0,
      accessToken: '',
      refreshToken: '',
    },
  });
};
