import _ from 'lodash';
import React, { useEffect } from 'react';
import { FastField, Field } from 'formik';

interface HiddenInputProps {
  name: string;
  defaultValue?: any;
  disableFastField?: boolean;
  formik?: any;
  className?: string;
  handleChange?: Function;
}

const HiddenInput: React.FC<HiddenInputProps> = ({
  name = '',
  formik,
  handleChange,
  className = '',
  defaultValue,
  disableFastField = false,
}) => {
  const FormField = disableFastField ? Field : FastField;

  useEffect(() => {
    if (formik && handleChange && defaultValue) {
      formik.setFieldValue(name, defaultValue);
      handleChange(null, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormField name={name}>
        {({ form }) => {
          const value = _.get(form.values, name);
          return (
            <>
              <input
                className={className}
                type="hidden"
                name={name}
                value={value === undefined ? defaultValue : value}
              />
            </>
          );
        }}
      </FormField>
    </>
  );
};

export default HiddenInput;
