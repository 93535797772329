import React from 'react';

import TextField from '@mui/material/TextField';

const InputComponent: any = React.forwardRef((props, ref: any) => <div {...props} ref={ref} />);

interface OultinedDivProps {
  labelProps: any;
  variant: any;
  children: React.ReactNode;
}

const OutlinedDiv: React.FC<OultinedDivProps> = ({ children, labelProps, variant }) => {
  return (
    <TextField
      {...labelProps}
      variant={variant}
      multiline
      InputProps={{
        inputComponent: InputComponent,
      }}
      inputProps={{ children: children }}
    />
  );
};
export default OutlinedDiv;
