import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';

import { GridWrapper, IonPageWrapper, ListWrapper } from './styles';
import GroupModel from '$business/models/group';
import { directoryActions } from '$business/redux/directory';
import MainHeader from '$components/mainHeader';
import CurrentStateModel from '$business/models/currentState';
import { PageWrapper } from '$gstyles/wrapper';
import Search from '$components/mainHeader/search';
import MemberItem from './memberItem';
import ProfileModal from './profileModal';
import MemberBox from './memberBox';
import { ListSubheader } from '@mui/material';

interface GroupScreenProps {
  onHydrate;
  onDehydrate;
  group: GroupModel;
  currentState: CurrentStateModel;
}

const GroupScreen: React.FC<GroupScreenProps> = ({ group, onHydrate, currentState }) => {
  const [member, setMember] = useState<any>(null);
  const useGrid = true;

  const items = group?.items || [];

  const handleMemberClick = (m) => {
    if (!m.jejik) return;
    setMember(m);
  };

  return (
    <IonPageWrapper>
      <MainHeader disableMenu currentState={currentState} title={group?.name} />
      <IonContent>
        <PageWrapper className="search-wrapper">
          <Search onSelected={(m) => setMember(m)} />
        </PageWrapper>
        {useGrid ? (
          <>
            {(items || []).map((g, i) => (
              <div key={i} className="page-break-after">
                <ListSubheader className="primary filled">{g.name}</ListSubheader>
                <GridWrapper>
                  {(g.members || []).map((item, j) => (
                    <MemberBox key={j} onClick={handleMemberClick} member={item} />
                  ))}
                </GridWrapper>
              </div>
            ))}
          </>
        ) : (
          <ListWrapper>
            {(items || []).map((m, i) => (
              <MemberItem key={i} onClick={(m) => setMember(m)} member={m} />
            ))}
          </ListWrapper>
        )}
      </IonContent>
      <ProfileModal show={!!member} onClose={() => setMember(null)} member={member} />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  group: state.directory.group,
  // members: state.directory.members,
});

const mapDispatchToProps = {
  onHydrate: (params) => {
    return directoryActions.fetchGroup({ groupId: parseInt(params.id || 0) });
  },
  onDehydrate: directoryActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(GroupScreen));
