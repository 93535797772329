import React from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import MemberModel from '$business/models/member';
import ProfileComponent from './profile';
import { ProfileModalWrapper } from './styles';

interface ProfileModalProps {
  show: boolean;
  onClose: Function;
  member: MemberModel;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ show, onClose, member }) => {
  return (
    <ProfileModalWrapper titleText={member?.name || ''} show={show} onClose={() => onClose()} useCustom>
      <IonContent>{member && <ProfileComponent member={member} />}</IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="center">
          <Button variant="outlined" onClick={() => onClose()}>
            {intl('BUTTON.CLOSE')}
          </Button>
        </Flex>
      </IonFooter>
    </ProfileModalWrapper>
  );
};

export default ProfileModal;
