export default interface UserModel {
  userId: number;
  email?: string;
  phone: string;
  name: string;
  userLevel?: number;
  status: string;
  groups: Array<number>;
  ls?: boolean;
  settings?: any;
}

export const emptyUser: UserModel = {
  userId: 0,
  name: '',
  email: '',
  phone: '',
  groups: [],
  status: '',
};

export const defaultUser = emptyUser;

export const getFullName = (user) => {
  if (!user) return '';
  return `${user?.firstName || ''} ${user?.lastName || ''}`;
};

export const getMobile = (user) => {
  if (user?.other) return '';
  return user?.other?.mobile || '';
};

export const deriveRawToUser = (raw) => {
  if (!raw) return defaultUser;

  const { id, level, userId, name, groups, phone, status, settings, ls } = raw;

  return {
    userId: userId || id,
    status,
    name,
    phone,
    groups,
    ls,
    userLevel: level,
    ...(settings && { settings }),
  };
};
