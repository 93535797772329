import React from 'react';
import { connect } from 'react-redux';
import { IonAlert, IonContent, IonPage } from '@ionic/react';
import intl, { Text } from '$gintl';

import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { FormSection, LogoHeader } from '$gcomponents/reusables';
import { Box, SPACE, Space } from '$gstyles';
import PATH from '$gbusiness/enums/paths';

import { Button, Link } from '$gcomponents/primitives';
import { Form, Formik } from 'formik';
import FORGOT_FORM from './forgotForm';
import { input } from '$gbusiness/helpers';
import { initActions } from '$business/redux/init';

interface ForgotScreenProps {
  requestPasswordReset: Function;
  screenState: any;
  history: any;
}

const ForgotPasswordScreen: React.FC<ForgotScreenProps> = ({
  requestPasswordReset,
  screenState,
  history,
}) => {
  const onSubmit = (values) => requestPasswordReset(values.mobile);

  const forgotForm = FORGOT_FORM;
  const validateForm = (values) => {
    return input.validateError(forgotForm, values);
  };

  return (
    <IonPage id="forgot-screen">
      <Header title="SCREEN.FORGOT.TITLE" />
      <IonContent>
        <IonAlert
          isOpen={screenState !== ''}
          header={intl('MESSAGE.SUBMITTED')}
          message={intl('MESSAGE.PASSWORD_RESET')}
          onDidDismiss={() => {
            history.goBack();
          }}
          buttons={['OK']}
        />
        <Box.LARGE>
          <LogoHeader />
          <Formik
            enableReinitialize={true}
            initialValues={{ state: '' }}
            validate={validateForm}
            onSubmit={(values) => {
              onSubmit(values);
            }}>
            {(formik) => (
              <Box.MEDIUM padding={SPACE.LARGE}>
                <Form>
                  <FormSection
                    title="SCREEN.FORGOT.SUBTITLE"
                    FORM={forgotForm}
                    formik={formik}
                    marginBottom="0"
                  />
                  <Button
                    color="primary"
                    fullWidth
                    onClick={formik.handleSubmit}
                    disabled={!(formik.isValid && formik.dirty)}>
                    <Text k="SCREEN.FORGOT.SUBMIT_BUTTON" />
                  </Button>
                </Form>
              </Box.MEDIUM>
            )}
          </Formik>
          <Space.Ver margin={SPACE.LARGE}>
            <Link route={PATH.LOGIN} routerDirection="back" button>
              <Text k="SCREEN.FORGOT.LOGIN_LINK" />
            </Link>
          </Space.Ver>
        </Box.LARGE>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  requestPasswordReset: initActions.requestPasswordReset,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ForgotPasswordScreen));
