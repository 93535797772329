export const KEYS = {
  ONE: '1부',
  TWO: '2부',
  THREE: '3부',
  OICC: 'OICC',
};

export const WORSHIPS = KEYS;

const LIST = Object.keys(KEYS);

export const WORSHIP_OPTIONS = LIST.map((item) => ({
  label: `WORSHIP.${item}`,
  value: item,
}));

export default WORSHIP_OPTIONS;
