import styled from 'styled-components';
import { IonPage } from '@ionic/react';

export const AttendanceScreenWrapper = styled(IonPage)`
  .avatar {
    left: 0px;
  }
  .past-sunday {
    .checkbox.absent {
      opacity: 0.4;
      pointer-events: none;
    }
  }

  div.table {
    .list-row {
      display: flex;
      padding-right: 6px;
      min-height: 42px;
      justify-content: space-between;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: normal;
      }
      .info {
        min-width: 100px;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        > div {
          margin: 2px;
        }
        .phone {
          margin-left: 2px;
        }
        .photo {
          margin-left: -4px;
        }
      }
      .option {
        width: 40px;
        text-align: center;
        word-break: keep-all;
      }
      .option:last-child {
        margin-right: 0px;
      }
    }
    .MuiListItem-root {
      border-bottom: 1px solid #ccc;
    }
  }

  .table {
    .MuiTableCell-head {
      font-size: 1em;
      letter-spacing: -1px;
    }
    .MuiSvgIcon-root {
      font-size: 28px;
    }
    .memo-label {
      width: 40px;
    }
    .memo-value {
      flex: 1;
      min-width: auto;
      max-width: 300px;
      width: 90%;
    }
    .name.phone {
      line-height: 20px;
    }
    .absent {
      position: relative;
      width: 100%;
      min-height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      .reason {
        font-size: 12px;
        letter-spacing: -1px;
        position: absolute;
        max-height: 24px;
        min-height: 24px;
        display: flex;
        bottom: 4px;
        justify-content: center;
        align-items: center;
        line-height: normal;
        left: 0;
        right: 0;
        width: 100%;
      }
    }
  }
  .attend-date-select {
    min-width: 300px;
  }
  .submit-button {
    min-width: 130px;
  }
`;
