import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { ROUTES } from '$gbusiness/enums';
import CurrentStateModel from '$business/models/currentState';
import UserModel from '$business/models/user';

export interface PrivateRouteProps extends RouteProps {
  currentState: CurrentStateModel;
  isAccessible: boolean;
  feature?: string;
  component: React.FC;
  user?: UserModel;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  user,
  component,
  feature,
  currentState,
  isAccessible,
  ...rest
}) => {
  const { isLoggedIn } = currentState;
  const routeComponent = (props: any) =>
    isLoggedIn ? (
      React.createElement(component, { ...props, currentState, feature, user })
    ) : (
      <Redirect to={currentState.defaultPath || ROUTES.DEFAULT_PUBLIC_PATH} />
    );

  return isAccessible ? <Route {...rest} render={routeComponent} /> : null;
};

export default PrivateRoute;
