import React from 'react';
import intl from '$intl';
import { BSLabel, FormControlWrapper } from './styles';
import { NativeSelect as MuiSelect, OutlinedInput } from '@mui/material';

interface NativeSelectProps {
  onChange: Function;
  value: any;
  label?: string;
  variant?: string;
  placeholder?: string;
  size?: any;
  color?: any;
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
  isNumeric?: boolean;
  shrink?: boolean;
  options?: Array<any>;
  children?: React.ReactNode;
}

const NativeSelect: React.FC<NativeSelectProps> = ({
  disabled = false,
  options,
  isNumeric = true,
  onChange,
  fullWidth = false,
  size = 'small',
  variant = 'outlined',
  label = '',
  color = 'primary',
  children,
  className,
  shrink = true,
  value,
  placeholder,
}) => {
  const handleChange = (e) => {
    onChange(isNumeric ? Number(e.target.value) : e.target.value);
  };
  return (
    <FormControlWrapper className={className} size={size} variant={variant} fullWidth={fullWidth}>
      <BSLabel id={`label_${label}`} className={!variant && 'bs-label'} shrink={shrink}>
        {intl(label)}
      </BSLabel>
      <MuiSelect
        color={color}
        input={<OutlinedInput label={intl(label)} notched />}
        disabled={disabled}
        placeholder={placeholder ? intl(placeholder) || undefined : undefined}
        onChange={handleChange}
        value={isNumeric ? Number(value) : value}>
        {children
          ? children
          : (options || []).map((o, i) => (
              <option key={i} value={o.value}>
                {o.labelText !== null ? o.labelText : intl(o.label)}
              </option>
            ))}
      </MuiSelect>
    </FormControlWrapper>
  );
};

export default NativeSelect;
