import styled from 'styled-components';
import { InputLabel, FormControl } from '@mui/material';

export const FormControlWrapper = styled(FormControl)`
  .MuiSelect-select {
    text-align: left;
  }
  .MuiInputBase-root {
  }
`;

export const BSLabel = styled(InputLabel)`
  &&.bs-label {
    position: absolute;
    left: 0;
    font-size: 13px;
    font-weight: 500;
    transform: none;
  }
`;
