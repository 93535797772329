import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';

import { AttendanceScreenWrapper } from './styles';
import MainHeader from '$components/mainHeader';
import intl from '$gintl';
import { Button } from '$gcomponents/primitives';

import Footer from '$gcomponents/widgets/footer';

import { PageWrapper } from '$gstyles/wrapper';
import AttendanceFilter, { getLastSunday } from './attendanceFilter';
import { directoryActions } from '$business/redux/directory';

import { toNumber } from '$gbusiness/helpers/util';
import { apiService } from '$gbusiness/services';
import { configs } from '$configs';
import { deriveRawToAttendance } from '$business/models/attendance';
import AttendanceList from './attendanceList';

interface AttendanceScreenProps {
  currentState;
  absents;
  worships;
  submitAttendance;
  history;
}

const AttendanceScreen: React.FC<AttendanceScreenProps> = ({
  currentState,
  absents,
  worships,
  submitAttendance,
}) => {
  const thisSunday = getLastSunday();
  const groups = currentState.user?.groups || [];
  const [filter, setFilter] = useState<any>({
    groupId: groups.length > 0 ? groups[0] : null,
    attendDate: thisSunday,
  });
  const [users, setUsers] = useState<any>([]);
  const [alreadyDone, setAlreadyDone] = useState(false);

  useEffect(() => {
    fetchAttendance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const fetchAttendance = () => {
    apiService
      .fetchApi({
        url: configs.api.attendance.get,
        param: filter,
      })
      .then((result) => {
        setUsers(result.list.map(deriveRawToAttendance));
        setAlreadyDone(result.exists);
      });
  };

  const isThisSunday = thisSunday === filter.attendDate;

  const updateFilter = async (obj) => {
    await setFilter({ ...filter, ...obj, forceRefresh: !filter?.forceRefresh });
  };

  const handleAttend = (user, value) => {
    onConfirmAttend(user.mid, value);
  };

  const onConfirmAttend = (id, attended) => {
    const newUsers = users.map((user) => {
      if (user.mid !== id) return user;
      return {
        ...user,
        attended: toNumber(attended),
      };
    });
    setUsers(newUsers);
  };

  const handleAbsent = (id, reason, note) => {
    const newUsers = users.map((user) => {
      if (user.mid !== id) return user;
      return {
        ...user,
        attended: 0,
        settings: {
          reason,
          note,
        },
      };
    });
    setUsers(newUsers);
  };

  const onSubmit = () => {
    const ids = users.map((m) => ({ mid: m.mid, attended: m.attended, settings: m.settings }));

    submitAttendance({
      ...filter,
      ids,
      authorId: currentState?.user?.userId || 0,
    }).then(() => {
      // updateFilter({});
    });
  };

  // console.log('USERS', users);

  return (
    <AttendanceScreenWrapper>
      <MainHeader currentState={currentState} title={intl('SCREEN.ATTENDANCE.TITLE')} />
      <IonContent>
        <PageWrapper>
          <AttendanceFilter onChangeFilter={updateFilter} filter={filter} />
        </PageWrapper>

        <AttendanceList
          worships={worships}
          absents={absents}
          users={users}
          className={`attendance ${isThisSunday ? '' : 'past-sunday'}`}
          handleAbsent={handleAbsent}
          handleAttend={handleAttend}
        />
      </IonContent>
      <Footer justifyContent="center">
        <Button className="third submit-button" onClick={onSubmit} disabled={!isThisSunday}>
          {intl('SCREEN.ATTENDANCE.' + (alreadyDone ? 'BUTTON_RESUBMIT' : 'BUTTON_SUBMIT'))}
        </Button>
      </Footer>
    </AttendanceScreenWrapper>
  );
};

const mapStateToProps = (state) => ({
  absents: state.init.absents,
  worships: state.init.worships,
});

const mapDispatchToProps = {
  submitAttendance: directoryActions.submitAttendance,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(AttendanceScreen));
