import {
  IonToolbar,
  IonButtons,
  IonMenuToggle,
  IonTitle,
  IonBackButton,
  IonButton,
  IonIcon,
  isPlatform,
} from '@ionic/react';
import React, { useState } from 'react';
import { Menu } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { IonHeaderWrapper } from './styles';
import { Split } from '$gstyles/wrapper';
import LoginModal from '../loginModal';
import CurrentStateModel from '$business/models/currentState';
import { arrowBack } from 'ionicons/icons';

interface MainHeaderProps {
  currentState: CurrentStateModel;
  title: string;
  disableMenu?: boolean;
  backRoute?: string;
  backText?: string;
}

const MainHeader: React.FC<MainHeaderProps> = ({
  currentState,
  title,
  backRoute,
  backText = '',
  disableMenu = false,
}) => {
  const [showLogin, setShowLogin] = useState(false);

  const isDesktop = isPlatform('desktop');

  const SideMenuToggleIcon = (
    <Split>
      {(!disableMenu && !backRoute) || isDesktop ? (
        <IonMenuToggle menu="start">
          <IconButton className="link">
            <Menu />
          </IconButton>
        </IonMenuToggle>
      ) : !!backRoute ? (
        <>
          <IonButton routerLink={backRoute} routerDirection="back">
            <IonIcon slot="icon-only" icon={arrowBack} />
          </IonButton>
          {backText}
        </>
      ) : (
        <IonBackButton />
      )}
    </Split>
  );

  return (
    <>
      <IonHeaderWrapper id="sidemenu">
        <IonToolbar color="light">
          <IonButtons slot="start">{SideMenuToggleIcon}</IonButtons>
          <IonTitle>{title}</IonTitle>
          {/* {isDesktop && (
            <IonButtons slot="end">
              <Print className="pointer" onClick={() => window.print()} />
            </IonButtons>
          )} */}
          <LoginModal disableRegister={true} show={showLogin} onClose={() => setShowLogin(false)} />
        </IonToolbar>
      </IonHeaderWrapper>
    </>
  );
};

export default MainHeader;
