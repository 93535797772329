import { ListItem } from '@mui/material';
import styled from 'styled-components';

export const ListItemWrapper = styled(ListItem)`
  &.list-item {
    display: flex;
    align-items: ${(p) => p.aligncenter};
    justify-content: space-between;
    ${(p) => p.width && `width: ${p.width};`}
  }

  .label {
    text-align: left;
    opacity: 0.7;
  }
  .value {
    flex: 1;
    text-align: right;
  }
`;
