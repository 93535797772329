import styled from 'styled-components';

import { ENUMS, SPACE } from '$gstyles/enums';

export const Wrapper = styled.div`
  ${(p) =>
    p.responsive
      ? ''
      : `
    height: 100%;
  `}

  ${(p) => (p.stickyHeader ? '' : 'overflow: auto;')}

  @media screen and (min-width: ${ENUMS.DESKTOP_MIN_WIDTH}) {
    .sort-item {
      display: none;
    }
  }
`;

export const NoResult = styled.div`
  margin: ${SPACE.LARGE};
`;
