import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { LogoHeader } from '$gcomponents/reusables';
import { Box, SPACE, Space } from '$gstyles';
import PATH from '$business/enums/paths';

import { Link } from '$gcomponents/primitives';
import { initActions } from '$business/redux/init';
import { ConfirmScreenWrapper } from './styles';
import { useLocation } from 'react-router';

interface MessageScreenProps {
  title: string;
  history: any;
}

const MessageScreen: React.FC<MessageScreenProps> = ({ title, history }) => {
  const location: any = useLocation();

  const message = location?.state?.message;

  return (
    <ConfirmScreenWrapper id="confirm-code-screen">
      <Header title="SCREEN.ONBOARDING.TITLE" />
      <IonContent>
        <Box.LARGE className="center">
          <LogoHeader />
          <div className="center large">{message}</div>
          {/* <Button onClick={onSubmit} className="center">
            {intl('BUTTON.CONFIRM')}
          </Button> */}
          <Space.Ver margin={SPACE.LARGE}>
            <Link route={PATH.LOGIN} routerDirection="back" button>
              Go To Login
            </Link>
          </Space.Ver>
        </Box.LARGE>
      </IonContent>
    </ConfirmScreenWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  status: state.init.status,
  phone: state.localStorage.phone,
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  verify: initActions.verifyCode,
  resend: initActions.resendCode,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MessageScreen));
