import GroupModel from '../../models/group';
import MemberModel from '../../models/member';
import UserModel from '../../models/user';

export const FETCH_ROOT_GROUP_SUCCESS = 'FETCH_ROOT_GROUP_SUCCESS';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const DIRECTORY_FAILURE = 'DIRECTORY_FAILURE';
export const CLEAN_DIRECTORY = 'CLEAN_DIRECTORY';

// Action Types
export interface typeOnlyType {
  type: typeof CLEAN_DIRECTORY;
}

export interface directoryFailureType {
  type: typeof DIRECTORY_FAILURE;
  err: string;
}
export interface fetchRootGroupsType {
  type: typeof FETCH_ROOT_GROUP_SUCCESS;
  group: GroupModel;
}
export interface fetchGroupType {
  type: typeof FETCH_GROUP_SUCCESS;
  group: GroupModel;
  members: Array<MemberModel>;
}

export interface fetchMemberType {
  type: typeof FETCH_GROUP_SUCCESS;
  member: MemberModel;
}

export interface fetchUsersType {
  type: typeof FETCH_USERS_SUCCESS;
  users: Array<UserModel>;
}

export interface DirectoryReducerType {
  group: GroupModel | null;
  member: MemberModel | null;
  members: Array<MemberModel>;
  users: Array<UserModel>;
}

export type DirectoryActionTypes =
  | typeOnlyType
  | directoryFailureType
  | fetchRootGroupsType
  | fetchGroupType
  | fetchMemberType
  | fetchUsersType;

export const DIRECTORY_INIT_STATE: DirectoryReducerType = {
  group: null,
  member: null,
  members: [],
  users: [],
};
