import React from 'react';
import MemberModel from '$business/models/member';
import Img from '$gcomponents/primitives/img';
import { PageWrapper } from '$gstyles/wrapper';
import { InfoItem } from '$gcomponents/reusables';
import { Avatar } from '@mui/material';
import { emailLink, phoneLink } from './utils';

interface ProfileComponentProps {
  member: MemberModel;
}

const ProfileComponent: React.FC<ProfileComponentProps> = ({ member }) => {
  // const deriveGroup = (arr) => {
  //   return arr.map((m) => m.name).join(',');
  // };

  return (
    <PageWrapper>
      <div>
        {member?.imagePath ? (
          <Img
            className="profile-image"
            size="contain"
            width="calc(100% - 60px)"
            height="300px"
            src={`${process.env.REACT_APP_BASE}${member.imagePath}`}
          />
        ) : (
          <Avatar sx={{ width: 100, height: 100 }} className="profile-image">
            {member?.name}
          </Avatar>
        )}
      </div>
      <div className="profile-info-section">
        <InfoItem label="SCREEN.PROFILE.NAME" value={member.kname || member.name} />
        <InfoItem label="SCREEN.PROFILE.PHONE" value={phoneLink(member.phone, true)} />
        <InfoItem label="SCREEN.PROFILE.EMAIL" value={emailLink(member.email, true)} />
        {/* <InfoItem label="SCREEN.PROFILE.JEJIK" value={member.jejik || ''} /> */}
        <InfoItem label="SCREEN.PROFILE.JEJIK" value={member.jejik || ''} />
      </div>
    </PageWrapper>
  );
};

export default ProfileComponent;
