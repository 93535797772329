import styled from 'styled-components';
import { IonMenu } from '@ionic/react';

import { configs } from '$configs';
import { FONT, SPACE, WEIGHT } from '$gstyles';

const SELECTED_COLOR = '#fff';

export const SideMenuStyle = `
  ion-content,
  ion-footer,
  ion-list,
  #background-content,
  ion-list-header,
  ion-item,
  button,
  ion-toolbar,
  .menu-item a,
  div {
    border-color: var(--ion-color);
    --background: var(--ion-color-medium);
    background: var(--ion-color-medium);
    color: var(--ion-color);
    font-weight: ${WEIGHT.NORMAL};
  }
  .list-md {
    background: var(--ion-color-medium);
    color: var(--ion-color);
  }
  ion-item.item ion-label,
  .menu-item a,
  ion-item.item .MuiSvgIcon-root {
    font-weight: ${WEIGHT.NORMAL};
    font-size: ${FONT.MEDIUM} !important;
    margin-right: ${SPACE.MEDIUM};
  }
  ion-item.item .MuiSvgIcon-root {
    font-size: ${FONT.LARGE} !important;
  }
  ion-item.item:hover {
    background-color: ${SELECTED_COLOR};
    ion-label {
      color: var(--ion-color);
      font-weight: ${WEIGHT.NORMAL};
    }
  }
  ion-item.selected,
  ion-item:hover::part(native) {
    --background: ${SELECTED_COLOR};
  }
  ion-item:hover::part(native) {
    color: ${SELECTED_COLOR};
  }
  a.selected, ion-item.selected, ion-item.item:hover {
    --background: ${SELECTED_COLOR};
    background-color: ${SELECTED_COLOR};
  }
  .outer.selected {
    background-color: ${SELECTED_COLOR};
    .MuiAccordionSummary-expandIcon,
    .MuiAccordionSummary-root,
    .MuiAccordionSummary-content {
      background-color: ${SELECTED_COLOR};
    }
  }

  .MuiSvgIcon-root {
    margin-top: ${SPACE.MEDIUM};
    margin-bottom: ${SPACE.MEDIUM};
  }

  ion-item::part(native) {
    min-height: ${SPACE.LARGE};
    font-size: ${FONT.SMALL};
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiAccordion-root
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: initial;
    pointer-events: none;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  .MuiPaper-root {
    background-color: transparent;
    font-size: 1.4rem;
    ion-item {
      pointer-events: auto;
    }
    .MuiAccordionSummary-root {
      padding: 0 ${SPACE.SMALL};
      padding-left: ${SPACE.MEDIUM};
    }
    .MuiAccordionDetails-root,
    .menu-item > div {
      padding: 0;
    }
    .menu-item > div a {
      display: block;
      padding: ${SPACE.MEDIUM};
      padding-left: 20px;
    }
    ion-list {
      padding: 0;
      width: 100%;
    }
  }

  .pop-button-container {
    margin: ${SPACE.MEDIUM};
    display: block;
  }

  .logo {
    height: 52px;
    display: inline-block;
    padding: 8px 16px;
  }

  .app-version {
    display: inline-block;
    height: ${(props) => (props.isProd ? '14' : '28')}px;
    position: absolute;
    padding: ${SPACE.MEDIUM};
    right: ${SPACE.SMALL};
    font-size: 1.15rem;
    opacity: 0.5;
  }

  &.md {
    ion-note {
      padding-left: ${SPACE.MEDIUM};
    }
    ion-item {
      --padding-start: ${SPACE.MEDIUM};
      --padding-end: ${SPACE.MEDIUM};
      /* border-radius: 4px; */
    }

    .MuiSvgIcon-root {
      color: var(--ion-color);
    }

    ion-item ion-label {
    }
  }

  /* .dept-item ion-item {
    ion-label {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
  } */
  .cat-item ion-item {
    border-top: 1px solid #ccc;
    margin: 0;
    padding: 6px;
    text-transform: uppercase;
  }
  .outer:first-child ion-item {
    border-color: transparent;
  }
  .cat-item.root-category ion-item {
    ion-label {
      font-weight: ${WEIGHT.SEMI_BOLD};
      font-size: 1.1.em;
    }
  }
  .cat-item ion-item::part(native) {
    padding-left: 12px;
    height: 20px;
    border-left: 4px solid transparent;
  }
  .cat-item.root-category ion-item::part(native) {
    padding-left: 12px;
    height: 20px;
    border-left: 4px solid var(--ion-color-tertiary);
  }

  .outer {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .MuiAccordion-root {
    box-shadow: none;
    ion-item {
      border-top: none;
    }
    .MuiAccordionSummary-expandIcon {
      padding: 0 12px;
      margin-right:0;
      width:32px;
      pointer-events: auto;
    }
    .MuiAccordionSummary-root {
      padding: 0;
      border-top: 1px solid #ccc;
      min-height: initial;
      .MuiAccordionSummary-content {
        margin: 0;
      }
    }
    .MuiAccordionSummary-root.Mui-focusVisible {
      background-color: var(--ion-color-background) !important;
    }
    .MuiAccordionDetails-root {
      display: block;
    }
    .MuiSvgIcon-root {
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
  .MuiAccordion-root:before {
    background-color: transparent;
  }
`;

export const Wrapper = styled(IonMenu)`
  @media screen and (min-width: ${configs.display.desktopMinThreshold}) {
    --side-max-width: ${configs.display.menuWidth}px;
    --side-min-width: ${configs.display.menuWidth}px;
  }
  border-width: 0px;

  ${SideMenuStyle}
`;
