import React from 'react';
import intl from '$intl';

import { Button } from '$gcomponents/primitives';
import { Form, Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { ABSENT_FORM } from './attendanceFilter';
import MuiModal from '$gcomponents/reusables/modal/material';

interface AbsentModalProps {
  user: any;
  absents: Array<any>;
  onClose: Function;
  onConfirm: Function;
}

const AbsentModal: React.FC<AbsentModalProps> = ({ user, absents, onClose, onConfirm }) => {
  const onSubmit = (values) => {
    const { reason, note } = values;
    onConfirm(user.mid, reason, note);
  };

  const absentForm = ABSENT_FORM(absents);
  const validateForm = (values) => input.validateError(absentForm, values);

  const settings = user?.settings || {};

  return (
    <MuiModal title="불참 이유" open={!!user} onClose={() => onClose()}>
      <Formik
        enableReinitialize={true}
        initialValues={{ reason: settings?.reason, note: settings?.note || '' }}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <Form>
              <FormSection FORM={absentForm} formik={formik} />
              <Button onClick={formik.handleSubmit} disabled={!formik.isValid}>
                {intl('BUTTON.CONFIRM')}
              </Button>
            </Form>
          </>
        )}
      </Formik>
    </MuiModal>
  );
};

export default AbsentModal;
