import { configs } from '$configs';

import HeadersModel from '$business/models/headers';

export const INIT_SUCCESS = 'INIT_SUCCESS';
export const INIT_LOGIN_SUCCESS = 'INIT_LOGIN_SUCCESS';
export const INIT_FAILURE = 'INIT_FAILURE';
export const PHONE_VERIFIED_SUCCESS = 'PHONE_VERIFIED_SUCCESS';
export const RESEND_SUCCESS = 'RESEND_SUCCESS';
export const PHONE_VERIFIED_FAILURE = 'PHONE_VERIFIED_FAILURE';
export const CODE_VERIFIED_SUCCESS = 'CODE_VERIFIED_SUCCESS';
export const CODE_VERIFIED_FAILURE = 'CODE_VERIFIED_FAILURE';
export const USER_EXISTS_SUCCESS = 'USER_EXISTS_SUCCESS';
export const MESSAGE_SUCCESS = 'MESSAGE_SUCCESS';
export const SUBMITTED_SUCCESS = 'SUBMITTED_SUCCESS';
export const LOAD_HEADERS = 'LOAD_HEADERS';
export const SET_MYGROUPS = 'SET_MYGROUPS';

// Action Types
export interface InitSuccessType {
  type: typeof INIT_SUCCESS;
}

export interface SetMyGroupsType {
  type: typeof SET_MYGROUPS;
  groups: any;
  absents: any;
  worships: any;
}

export interface statusChangeType {
  type:
    | typeof USER_EXISTS_SUCCESS
    | typeof RESEND_SUCCESS
    | typeof CODE_VERIFIED_SUCCESS
    | typeof SUBMITTED_SUCCESS
    | typeof PHONE_VERIFIED_FAILURE;
}

export interface verifySuccessType {
  type: typeof PHONE_VERIFIED_SUCCESS;
  phone: string;
}

export interface InitFailureType {
  type: typeof INIT_FAILURE | typeof CODE_VERIFIED_FAILURE;
  err: string;
}

export interface MessageActionType {
  type: typeof MESSAGE_SUCCESS;
  message: string;
}

export interface LoadHeadersType {
  type: typeof LOAD_HEADERS;
  headers: HeadersModel;
}

export interface LoginSuccessType {
  type: typeof INIT_LOGIN_SUCCESS;
  deviceId: string;
  accesToken: string;
  user: any;
  userId: any;
}

export interface InitModel {
  headers: HeadersModel;
  initSuccess: boolean;
  status: string;
  phone?: string;
  message?: string;
  public?: {
    resetRequested?: boolean;
    err?: string | undefined;
  };
  groups?: Array<number>;
  isLeader?: boolean;
  absents?: Array<any>;
  worships?: Array<any>;
}

export const headersInitState: HeadersModel = {
  env: (process.env.REACT_APP_ENV || '').toString(),
  appName: configs.appName,
  authtoken: '',
  deviceId: '',
  churchId: 1,
  userId: 1,
};

export const INIT_STATE: InitModel = {
  headers: headersInitState,
  initSuccess: false,
  public: undefined,
  status: '',
};
