import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';

import { ReviewScreenWrapper } from './styles';
import MainHeader from '$components/mainHeader';
import intl from '$gintl';
import { directoryActions } from '$business/redux/directory';
import { List, ListItem, ListItemText } from '@mui/material';
import { Button } from '$gcomponents/primitives';
import { initActions } from '$business/redux/init';
import { dialog } from '$gcomponents/reusables';

interface ReviewScreenProps {
  onHydrate;
  onDehydrate;
  currentState;
  approveUser: Function;
  history;
  users;
}

const ReviewScreen: React.FC<ReviewScreenProps> = ({ currentState, onHydrate, approveUser, users }) => {
  const onApprove = (user, approve) => {
    dialog.confirm({
      message: approve ? 'MESSAGE.APPROVE' : 'MESSAGE.REJECT',
      onPress: async () => {
        if (approve) await approveUser({ ...user.settings, id: user.userId }, approve);
        onHydrate();
      },
    });
  };
  return (
    <ReviewScreenWrapper>
      <MainHeader currentState={currentState} title={intl('SCREEN.REVIEW.TITLE')} />
      <IonContent>
        {users.length ? (
          <List>
            {(users || []).map((user, i) => {
              const {
                settings: { name, phone, cell },
              } = user;

              return (
                <ListItem key={i} onClick={(m) => {}}>
                  {/* <Avatar className="pointer">{profileAvatar(member, 64, true)}</Avatar> */}
                  <ListItemText
                    primary={name}
                    className="text"
                    secondary={
                      <React.Fragment>
                        <div>{phone}</div>
                        <div>{cell} 목장</div>
                      </React.Fragment>
                    }
                  />
                  <ListItemText className="buttons">
                    <Button size="small" className="success filled" onClick={() => onApprove(user, true)}>
                      Approve
                    </Button>
                    <Button size="small" className="danger filled" onClick={() => onApprove(user, false)}>
                      Reject
                    </Button>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <div className="no-result">{intl('MESSAGE.NO_RESULT')}</div>
        )}
      </IonContent>
    </ReviewScreenWrapper>
  );
};

const mapStateToProps = (state) => ({
  users: state.directory.users,
});

const mapDispatchToProps = {
  onHydrate: () => directoryActions.fetchWaitingUsers(),
  onDehydrate: directoryActions.dehydrate,
  approveUser: initActions.approveUser,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ReviewScreen));
