import {
  UserActionTypes,
  UserReducerType,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  SAVE_USER_SUCCESS,
  CLEAN_USER,
  USER_INIT_STATE,
} from './types';

export default function userReducer(
  state: UserReducerType = USER_INIT_STATE,
  action: UserActionTypes,
): UserReducerType {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case FETCH_USER_FAILURE:
      return USER_INIT_STATE;
    case SAVE_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
      };
    case CLEAN_USER:
      return USER_INIT_STATE;
    default:
      return state;
  }
}
