import React from 'react';
import intl from '$gintl';
import { ListItemWrapper } from './styles';

interface InfoItemProps {
  label: string;
  value: string;
  className?: string;
  alignCenter?: boolean;
  width?: string;
}

const InfoItem: React.FC<InfoItemProps> = ({
  label,
  width = '',
  value,
  alignCenter = false,
  className = '',
}) => {
  return (
    <ListItemWrapper
      width={width}
      className={`list-item ${className}`}
      aligncenter={alignCenter ? 'center' : 'flex-start'}>
      <div className="label">{intl(label)}</div>
      <div dangerouslySetInnerHTML={{ __html: value || '' }} className="value" />
    </ListItemWrapper>
  );
};

export default InfoItem;
