import { FONT } from '$gstyles';
import { Snackbar } from '@mui/material';
import styled from 'styled-components';

export const SnackbarWrapper = styled(Snackbar)`
  .MuiAlert-message {
    font-size: ${FONT.MEDIUM};
  }
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall {
    font-size: ${FONT.LARGE};
  }
`;
