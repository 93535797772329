export const KEYS = {
  SICK: '몸',
  TRIP: 'TRIP',
  VACATION: 'VACATION',
  WORK: 'WORK',
  OTHER: 'OTHER',
};

export const ABSENTS = KEYS;

const LIST = Object.keys(KEYS);

export const ABSENT_OPTIONS = LIST.map((item) => ({
  label: `ABSENT.${item}`,
  value: item,
}));

export default ABSENT_OPTIONS;
