import { INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';
import InputRowModel from '$gbusiness/models/inputRow';

const FORGOT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.MOBILE,
        placeholder: 'INPUT.PLACEHOLDER.MOBILE',
        validation: [
          input.requiredValidation,
          {
            condition: (v) => !input.validatePhone(v),
            message: 'INVALID_PHONE',
          },
        ],
        gridSize: 12,
      },
    ],
  },
];

export default FORGOT_FORM;
