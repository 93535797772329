export const KEYS = {
  GENERAL: 'GENERAL',
  TECH: 'TECH',
  INFO: 'INFO',
  DELETE: 'DELETE',
};

export const SUPPORT_CATEGORY = KEYS;

const LIST = Object.keys(KEYS);

export const SUPPORT_CATEGORY_OPTIONS = LIST.map((item) => ({
  label: `SUPPORT.${item}`,
  value: item,
}));

export default SUPPORT_CATEGORY_OPTIONS;
