import React from 'react';
import { Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { IonAlert, IonContent, IonPage } from '@ionic/react';
import intl, { Text } from '$gintl';

import PATH from '$gbusiness/enums/paths';
import { authActions } from '$gbusiness/redux/auth';
import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { FormSection, LogoHeader } from '$gcomponents/reusables';
import { Box, SPACE } from '$gstyles';
import { input } from '$gbusiness/helpers';

import RESET_FORM from './resetForm';
import { Button } from '$gcomponents/primitives';

interface ResetPasswordScreencreenProps {
  resetPassword: Function;
  screenState: string;
  match: any;
  history: any;
}

const ResetPasswordScreen: React.FC<ResetPasswordScreencreenProps> = ({
  resetPassword,
  screenState,
  match,
  history,
}) => {
  const {
    params: { token, email },
  } = match;
  const onSubmit = (values) => resetPassword(token, email, values.password);

  const validateForm = (values) => input.validateError(RESET_FORM, values);

  return (
    <IonPage id="forgot-screen">
      <Header title="SCREEN.RESET_PASSWORD.TITLE" />
      <IonContent>
        <IonAlert
          isOpen={screenState !== '' && screenState !== undefined}
          header={intl('MESSAGE.SUBMITTED')}
          message={intl('MESSAGE.PASSWORD_UPDATED')}
          onDidDismiss={() => {
            history.replace(PATH.LOGIN);
          }}
          buttons={['OK']}
        />
        <Box.LARGE>
          <LogoHeader />
          <Formik
            enableReinitialize={true}
            initialValues={{}}
            validate={validateForm}
            onSubmit={(values) => {
              onSubmit(values);
            }}>
            {(formik) => (
              <Box.MEDIUM padding={SPACE.SMALL}>
                <Form>
                  <FormSection title="SCREEN.RESET_PASSWORD.SUBTITLE" FORM={RESET_FORM} formik={formik} />
                  <Button onClick={formik.handleSubmit} disabled={!(formik.isValid && formik.dirty)}>
                    <Text k="SCREEN.RESET_PASSWORD.SUBMIT_BUTTON" />
                  </Button>
                </Form>
              </Box.MEDIUM>
            )}
          </Formik>
        </Box.LARGE>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  resetPassword: authActions.resetPassword,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ResetPasswordScreen));
