import { ROUTES as GROUTES } from '$gbusiness/enums';
import RouteModel from '$gbusiness/models/route';
import PATH from './paths';

import {
  Group,
  Business,
  Diversity1,
  HowToReg,
  Person,
  ContactSupportOutlined,
  PlaylistAdd,
  PlaylistAddCheck,
  ListAlt,
} from '@mui/icons-material';
import {
  ConfirmCodeScreen,
  ForgotPasswordScreen,
  GroupScreen,
  HomeScreen,
  LoginScreen,
  MessageScreen,
  MyAccountScreen,
  OnboardingScreen,
  ReviewScreen,
  SupportScreen,
  WaitScreen,
  AttendanceScreen,
} from '$product/screens';

// IMPORTS FROM GBOX

export { PATH };

export const HOME_ROUTE: RouteModel = {
  title: 'MENU.JEJIK',
  path: PATH.HOME,
  defaultId: '5',
  param: ':rootId?',
  icon: Group,
  component: HomeScreen,
};

export const DEPARTMENT_ROUTE: RouteModel = {
  title: 'MENU.DEPT',
  path: PATH.HOME,
  defaultId: '2',
  param: ':rootId?',
  icon: Business,
  component: HomeScreen,
};

export const CELL_ROUTE: RouteModel = {
  title: 'MENU.CELL',
  path: PATH.HOME,
  defaultId: '1',
  param: ':rootId?',
  icon: Diversity1,
  component: HomeScreen,
};

export const GROUP_ROUTE: RouteModel = {
  path: PATH.GROUP,
  defaultId: '0',
  param: ':id?',
  component: GroupScreen,
};

export const PROFILE_ROUTE: RouteModel = {
  path: PATH.PROFILE,
  defaultId: '0',
  param: ':id?',
  component: GroupScreen,
};

export const MYACCOUNT_ROUTE: RouteModel = {
  title: 'MENU.MYACCOUNT',
  path: PATH.MYACCOUNT,
  icon: Person,
  component: MyAccountScreen,
};

export const LOGIN_ROUTE: RouteModel = {
  path: PATH.LOGIN,
  param: ':message?',
  component: LoginScreen,
  exact: true,
};

export const ONBOARDING_ROUTE: RouteModel = {
  path: PATH.ONBOARDING,
  component: OnboardingScreen,
  exact: true,
};

export const CONFIRM_ROUTE: RouteModel = {
  path: PATH.VERIFY,
  component: ConfirmCodeScreen,
  exact: true,
};

export const REVIEW_ROUTE: RouteModel = {
  title: 'MENU.REVIEW',
  path: PATH.REVIEW,
  component: ReviewScreen,
  icon: HowToReg,
  accessLevel: 1,
  exact: true,
};

export const ATTENDANCE_ROUTE: RouteModel = {
  title: 'MENU.ATTENDANCE',
  path: PATH.ATTENDANCE,
  component: AttendanceScreen,
  icon: ListAlt,
  exact: true,
};

export const SUPPORT_ROUTE: RouteModel = {
  title: 'MENU.SUPPORT',
  path: PATH.SUPPORT,
  component: SupportScreen,
  icon: ContactSupportOutlined,
  exact: true,
};

export const WAIT_ROUTE: RouteModel = {
  path: PATH.WAIT,
  component: WaitScreen,
  exact: true,
};

export const MESSAGE_ROUTE: RouteModel = {
  path: PATH.MESSAGE,
  component: MessageScreen,
  exact: true,
};

export const FORGOT_PASSWORD_ROUTE: RouteModel = {
  path: PATH.FORGOT,
  component: ForgotPasswordScreen,
  exact: true,
};

export const PRIVATE_LIST: Array<RouteModel> = [
  HOME_ROUTE,
  DEPARTMENT_ROUTE,
  CELL_ROUTE,
  GROUP_ROUTE,
  ATTENDANCE_ROUTE,
  REVIEW_ROUTE,
  MYACCOUNT_ROUTE,
  SUPPORT_ROUTE,
];
export const PUBLIC_LIST2: Array<RouteModel> = [
  LOGIN_ROUTE,
  ONBOARDING_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  CONFIRM_ROUTE,
  MESSAGE_ROUTE,
  WAIT_ROUTE,
];

export const LIST: Array<RouteModel> = [...PUBLIC_LIST2, ...PRIVATE_LIST];

export const ACCOUNT_LIST = [];
export const PUBLIC_LIST = [...GROUTES.PUBLIC_LIST, ...PUBLIC_LIST2];

export const DEFAULT_PUBLIC_ROUTE = LOGIN_ROUTE;
export const DEFAULT_PUBLIC_PATH = DEFAULT_PUBLIC_ROUTE.path;

export const defaultRoute = HOME_ROUTE;
export const DEFAULT_APP_PATH = defaultRoute.path + '/' + defaultRoute.defaultId;
