import React from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';

import { authActions } from '$gbusiness/redux/auth';
import PATH from '$gbusiness/enums/paths';
import { Link } from '$gcomponents/primitives';
import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { Text } from '$gintl';
import { LogoHeader } from '$gcomponents/reusables';
import { Box, SPACE, Space } from '$gstyles';

import RegisterForm from '$gcomponents/widgets/registerForm';
import { features } from '$configs';
import { useHistory } from 'react-router';

interface RegisterScreenProps {
  isLoggedIn: boolean;
  register: Function;
  onRegister?: Function;
  privacyPolicy?: React.ReactNode;
}

const RegisterScreen: React.FC<RegisterScreenProps> = ({
  onRegister,
  privacyPolicy,
  register,
  isLoggedIn,
}) => {
  const history = useHistory();
  const onSubmit = (values) => {
    if (onRegister) {
      onRegister(values);
      return;
    }
    register(values);
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      if (!features?.doubleOptin) history.push('/');
      else history.replace(PATH.REGISTER_COMPLETE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <IonPage id="forgot-screen">
      <Header title="SCREEN.REGISTER.TITLE" />
      <IonContent>
        <Box.LARGE>
          <LogoHeader />
          <RegisterForm onSubmit={onSubmit} privacyPolicy={privacyPolicy || null} />
          <Space.Ver margin={SPACE.LARGE}>
            <Link route={PATH.LOGIN} routerDirection="back" button>
              <Text k="SCREEN.REGISTER.LOGIN_LINK" />
            </Link>
          </Space.Ver>
        </Box.LARGE>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  isLoggedIn: state.localStorage.accessToken !== '',
});

const mapDispatchToProps = {
  register: authActions.register,
};

const connected: any = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(RegisterScreen));
