import { Dispatch } from 'redux';

import { configs } from '$configs';
import { fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToUser, emptyUser } from '$gbusiness/models/user';
import {
  UserActionTypes,
  FETCH_USER_SUCCESS,
  CLEAN_USER,
  SAVE_USER_SUCCESS,
  SAVE_USER_FAILURE,
} from './types';
import { LOADING } from '../loading/types';

export function fetchUser(userId): any {
  return async (dispatch: Dispatch) => {
    if (!userId || isNaN(userId)) {
      dispatch({
        type: FETCH_USER_SUCCESS,
        user: emptyUser,
      });
      return;
    }

    dispatch({
      type: LOADING,
    });

    const response = await fetchApi({
      url: configs.api.users.one + userId,
      method: 'GET',
    });

    dispatch({
      type: FETCH_USER_SUCCESS,
      user: response.user,
    });
  };
}

export function saveUser(userId, user): any {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOADING,
      loadingText: 'PROGRESS.SAVING',
    });
    const response = await fetchApi({
      url: configs.api.users.general + (userId ? '/' + userId : ''),
      param: {
        userId,
        ...user,
      },
      method: userId ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, SAVE_USER_FAILURE, response, 'MESSAGE.SAVE_FAIL');
    } else {
      const user = deriveRawToUser(response.user);
      dispatch({
        type: SAVE_USER_SUCCESS,
        user,
      });
      handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function dehydrate(): UserActionTypes {
  return { type: CLEAN_USER };
}
