import styled from 'styled-components';
import { IonPage } from '@ionic/react';

export const SupportScreenWrapper = styled(IonPage)`
  .text {
    margin-left: 10px;
  }
  .buttons {
    flex: none;
    width: 150px;
    > span {
      width: 150px;
      justify-content: space-between;
      display: flex;
    }
  }
`;
