import _ from 'lodash';
import React from 'react';
import { FastField, Field } from 'formik';
import { TextField, TextFieldProps } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { configs } from '$configs';
import intl from '$gintl';

import { BSLabel } from './styles';
import { FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { parseISO } from 'date-fns/esm';

interface DateInputProps {
  label?: string;
  labelText?: string;
  name: string;
  formik?: any;
  size?: string;
  readonly?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  enterToSubmit?: boolean;
  shrink?: boolean;
  position?: TextFieldProps['variant'];
  minDate?: Date;
  maxDate?: Date;
  format?: string;
  defaultValue?: string;
  useDatetime?: boolean;
  handleChange?: Function;
  disableFastField?: boolean;
}

const DateInput: React.FC<DateInputProps> = (properties) => {
  const {
    label = '',
    labelText = '',
    className = '',
    name = '',
    enterToSubmit = false,
    formik = {},
    size = configs.display?.inputSize,
    readonly = false,
    disabled = false,
    placeholder = '',
    shrink = false,
    position = undefined,
    minDate = parseISO('2010'),
    maxDate = new Date(),
    disableFastField = false,
    format,
    handleChange,
    useDatetime = false,
  } = properties;
  const onChange = (selectedDate) => {
    //formik.setFieldValue(name, dateFormat(selectedDate, 'M/d/yyyy hh:mm a'));
    formik.setFieldValue(name, selectedDate);
    if (handleChange) handleChange(null, selectedDate);
  };

  const onKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (enterToSubmit && charCode === 13) {
      e.preventDefault();
      formik.handleSubmit();
    }
    return true;
  };

  // const onBlur = e => {
  //   // any blur handling goes here
  //   if (formik.handleBlur) formik.handleBlur(e);
  // };

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;

  const inputSize = size === 'small' || size === 'xsmall' ? 'small' : 'medium';
  const labelProps = position
    ? {
        label: labelText || intl(label),
        InputLabelProps: {
          ...(shrink && { shrink }),
        },
      }
    : {};
  const variant = position || 'outlined';
  const Picker = useDatetime ? DateTimePicker : DatePicker;
  const otherProps = {
    ...(format && { inputFormat: format }),
  };

  const FormField = disableFastField ? Field : FastField;

  return (
    <>
      <FormField name={name}>
        {({ form }) => {
          const dateValue = _.get(form.values, name) || null;
          return (
            <FormControl
              size={inputSize}
              className={`${className} ${size === 'xsmall' && 'xsmall'}`}
              fullWidth>
              {!position && <BSLabel className="bs-label">{labelText || intl(label)}</BSLabel>}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Picker
                  readOnly={readonly}
                  disabled={disabled}
                  onChange={onChange}
                  {...otherProps}
                  value={dateValue}
                  minDate={minDate}
                  maxDate={maxDate}
                  {...labelProps}
                  renderInput={(props) => (
                    <TextField
                      name={name}
                      {...props}
                      variant={variant}
                      error={hasError}
                      onKeyUp={onKeyPress}
                      size={inputSize}
                      helperText={errorMsg}
                      placeholder={intl(placeholder) || undefined}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          );
        }}
      </FormField>
    </>
  );
};

export default DateInput;
