import React, { useState } from 'react';
import { Flex } from '$gstyles/wrapper';
import { SPACE } from '$gstyles';
import Div from '$gstyles/div';
import { Autocomplete } from '$gcomponents/reusables';
import { configs } from '$configs';
import { SearchWrapper } from './styles';
import { deriveRawToMember } from '$business/models/member';
import { profileAvatar } from '../../screens/groupScreen/utils';
import ProfileModal from '../../screens/groupScreen/profileModal';

interface SearchProps {
  onSelected?: Function;
}

export const AUTO_COMPLETE = {
  method: 'POST',
  minChar: 1,
  clearOnSelect: true,
  renderSuggestion: (e) => {
    return (
      <Flex className="suggest-item">
        {e.jejik ? (
          <>
            {profileAvatar(e, 48)}
            <Div marginLeft={SPACE.SMALL}>
              <span className="name">{e.name}</span>
              <span className="caption">({e.jejik})</span>
            </Div>
          </>
        ) : (
          <>
            {profileAvatar(e, 48)}
            <Div marginLeft={SPACE.SMALL}>
              <span className="name">{e.name}</span>
            </Div>
          </>
        )}
      </Flex>
    );
  },
  getLabel: (obj) => obj.name,
  onEnter: () => {
    console.log('ENETER');
  },
  deriveRaw: (response) => {
    const result = (response.rows || []).map(deriveRawToMember);
    return result;
  },
};

const Search: React.FC<SearchProps> = ({ onSelected }) => {
  const [member, setMember] = useState<any>(null);
  return (
    <SearchWrapper className="no-print">
      <Autocomplete
        endpoint={configs.api.group.search}
        useFormData
        listKey="rows"
        AC={{
          ...AUTO_COMPLETE,
          onSelect: async (value) => {
            // on selected
            if (onSelected) onSelected(value);
            else setMember(value);
          },
        }}
        height="32px"
      />
      <ProfileModal show={!!member} onClose={() => setMember(null)} member={member} />
    </SearchWrapper>
  );
};

export default Search;
