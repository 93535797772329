import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';

import { SupportScreenWrapper } from './styles';
import MainHeader from '$components/mainHeader';
import intl from '$gintl';
import { Button } from '$gcomponents/primitives';
import { initActions } from '$business/redux/init';
import { dialog, FormSection } from '$gcomponents/reusables';
import { Form, Formik } from 'formik';
import { Box, SPACE } from '$gstyles';
import SUPPORT_FORM from './supportForm';
import { input } from '$gbusiness/helpers';
import { SUPPORT_CATEGORY } from '$business/models/supportCategory';

interface ReviewScreenProps {
  currentState;
  contactUs: Function;
  deleteAccount: Function;
  history;
  user;
}

const SupportScreen: React.FC<ReviewScreenProps> = ({ currentState, contactUs, deleteAccount, user }) => {
  const formRef = useRef<any>();
  const suportForm = SUPPORT_FORM;
  const validateForm = (values) => {
    return input.validateError(suportForm, values);
  };

  const onSubmit = (values) => {
    const param = {
      ...values,
      userId: user.id,
    };
    if (values.category === SUPPORT_CATEGORY.DELETE) {
      dialog.confirm({
        title: 'MESSAGE.WAIT',
        message: 'MESSAGE.DELETE_WARNING',
        onPress: onDeleteAccount,
      });
      return;
    }
    contactUs(param).then(() => {
      formRef.current.resetForm();
    });
  };

  const onDeleteAccount = async () => {
    deleteAccount();
  };

  return (
    <SupportScreenWrapper>
      <MainHeader currentState={currentState} title={intl('SCREEN.SUPPORT.TITLE')} />
      <IonContent>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{ name: user?.name, category: SUPPORT_CATEGORY.GENERAL }}
          validate={validateForm}
          onSubmit={(values) => {
            onSubmit(values);
          }}>
          {(formik) => (
            <Box.MEDIUM padding={SPACE.LARGE}>
              <Form>
                <FormSection FORM={suportForm} formik={formik} marginBottom="0" />
                <Button
                  color="primary"
                  fullWidth
                  onClick={formik.handleSubmit}
                  disabled={!(formik.isValid && formik.dirty)}>
                  {intl('SCREEN.SUPPORT.SUBMIT')}
                </Button>
              </Form>
            </Box.MEDIUM>
          )}
        </Formik>
      </IonContent>
    </SupportScreenWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.currentUser,
});

const mapDispatchToProps = {
  contactUs: initActions.contactUs,
  deleteAccount: initActions.deleteMyAccount,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(SupportScreen));
