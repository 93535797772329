import styled from 'styled-components';
import { InputLabel } from '@mui/material';

export const BSLabel = styled(InputLabel)`
  &&.MuiFormLabel-root {
    position: absolute;
    top: -8px;
    left: 0;
    font-size: 13px;
    font-weight: 500;
    transform: none;
  }
`;
