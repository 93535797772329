export default interface MemberModel {
  id: number;
  mid?: number;
  name: string;
  firstName?: string;
  lastName?: string;
  kname?: string;
  phone?: string;
  email?: string;
  imagePath?: any;
  thumb?: any;
  jejik?: string;
  depts?: Array<any>;
  settings?: any;
  isLeader?: boolean;
}

export const deriveRawToMember = (raw) => ({
  id: raw.mid,
  mid: raw.mid,
  name: raw.fullName || raw.name,
  ...(raw?.firstName && { firstName: raw.firstName }),
  ...(raw?.lastName && { lastName: raw.lastName }),
  ...(raw?.kname && { kname: raw.kname }),
  ...((raw?.phone || raw.mobile) && { phone: raw.phone || raw.mobile }),
  ...(raw?.email && { email: raw.email }),
  ...(raw?.firstName && { firstName: raw.firstName }),
  ...(raw.hasImage && { ...derivePortraitPath(raw) }),
  ...(raw.depts && { depts: JSON.parse(raw.depts) }),
  ...(raw.rank && { jejik: `${(raw.rank || '').trim()}` }),
  isLeader: !!raw.leader || !!raw.l,
});

export const derivePortraitPath = (member) => {
  if (!member.hasImage) return {};
  return {
    imagePath: '/img/' + member.mid + '.jpg?=1',
    thumb: '/img/thumb/' + member.mid + '.jpg?=1',
  };
};
