import React from 'react';
import { IonRouterOutlet } from '@ionic/react';

import { ROUTES } from '$business/enums';
import { canAccess } from '$gbusiness/helpers/util';
import CurrentStateModel from '$business/models/currentState';

import PrivateRoute from './privateRoute';
import PublicRoute from './publicRoute';
import UserModel from '$business/models/user';
import { Redirect, Route } from 'react-router';
import PATH from '$business/enums/paths';

interface RouterProps {
  currentState: CurrentStateModel;
  user?: UserModel;
  isLoggedIn: boolean;
}

const AppOutlet: React.FC<RouterProps> = ({ currentState, user, isLoggedIn }) => {
  const { isOnboarding } = currentState;
  const defaultPublicPath = isOnboarding ? PATH.ONBOARDING : ROUTES.DEFAULT_APP_PATH;

  return (
    <IonRouterOutlet id="main" animated={false}>
      {ROUTES.PRIVATE_LIST.map((menu, i) => {
        const isAccessible =
          menu.path === PATH.ATTENDANCE
            ? !!currentState.isLeader
            : canAccess(menu.accessLevel, user?.userLevel);
        return (
          <PrivateRoute
            key={i}
            user={user}
            exact={menu?.exact || true}
            path={`${menu.path}${menu.param ? `/${menu.param}` : ''}`}
            component={menu.component}
            currentState={currentState}
            feature={menu.feature}
            isAccessible={isAccessible}
          />
        );
      })}
      {ROUTES.PUBLIC_LIST.map((menu, i) => (
        <PublicRoute
          key={i}
          user={user}
          currentState={currentState}
          path={`${menu?.path}${menu.param ? `/${menu.param}` : ''}`}
          component={menu.component}
          exact={menu.exact || true}
        />
      ))}
      <Route
        path={ROUTES.DEFAULT_PUBLIC_PATH}
        component={isLoggedIn ? undefined : ROUTES.DEFAULT_PUBLIC_ROUTE.component}
        render={isLoggedIn ? () => <Redirect to={defaultPublicPath} exact /> : undefined}
        exact
      />
    </IonRouterOutlet>
  );
};

export default AppOutlet;
