import {
  CLEAN_DIRECTORY,
  FETCH_GROUP_SUCCESS,
  FETCH_MEMBER_SUCCESS,
  FETCH_ROOT_GROUP_SUCCESS,
  DirectoryReducerType,
  DIRECTORY_INIT_STATE,
  FETCH_USERS_SUCCESS,
} from './types';

export function directoryReducer(
  state: DirectoryReducerType = DIRECTORY_INIT_STATE,
  action,
): DirectoryReducerType {
  switch (action.type) {
    case FETCH_ROOT_GROUP_SUCCESS:
      return {
        ...state,
        group: action.group,
      };
    case FETCH_GROUP_SUCCESS:
      return {
        ...state,
        group: action.group,
        members: action.members,
      };
    case FETCH_MEMBER_SUCCESS:
      return {
        ...state,
        member: action.member,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
      };
    case CLEAN_DIRECTORY:
      return DIRECTORY_INIT_STATE;
    default:
      return state;
  }
}
