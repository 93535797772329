import { LOAD_STORAGE, SET_ITEM, SET_ITEMS, LOCAL_STORAGE_INIT_STATE } from './types';

export default function localStorageReducer(state = LOCAL_STORAGE_INIT_STATE, action) {
  if (action.type.startsWith(SET_ITEM + '_')) {
    return {
      ...state,
      ...action.payload,
    };
  }
  switch (action.type) {
    case SET_ITEM:
      return {
        ...state,
        ...action.payload,
      };
    case SET_ITEMS:
      return {
        ...state,
        ...action.payload,
      };
    case LOAD_STORAGE:
      return action.localStorage;
    default:
      return state;
  }
}
