import { INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';
import InputRowModel from '$gbusiness/models/inputRow';

const MYACCOUNT_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.FIRST_NAME,
        value: 'name',
        validation: [input.requiredValidation],
        gridSize: 12,
      },
      {
        ...INPUT_TYPES.MOBILE,
        placeholder: 'INPUT.PLACEHOLDER.MOBILE',
        value: 'phone',
        validation: [
          input.requiredValidation,
          {
            condition: (v) => !input.validatePhone(v),
            message: 'INVALID_PHONE',
          },
        ],
        gridSize: 12,
      },
      {
        ...INPUT_TYPES.PASSWORD,
        placeholder: 'INPUT.PLACEHOLDER.PASSWORD_CHANGE',
        value: 'passwd',
        gridSize: 12,
      },
    ],
  },
];

export default MYACCOUNT_FORM;
