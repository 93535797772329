import React from 'react';
import { Formik, Form } from 'formik';

import { input } from '$gbusiness/helpers';
import { Button, Text } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { Box, SPACE } from '$gstyles';

import LOGIN_FORM from '$gbox/screens/loginScreen/loginForm';

interface LoginFormProps {
  onSubmit: Function;
  loginForm?: any;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit, loginForm }) => {
  const FORM = loginForm || LOGIN_FORM;
  const validateForm = (values) => {
    return input.validateError(FORM, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ state: '' }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <Box.MEDIUM padding={SPACE.LARGE}>
          <Form>
            <FormSection
              title="SCREEN.LOGIN.FORM_TITLE"
              FORM={loginForm || FORM}
              formik={formik}
              marginBottom="0"
            />
            <Button
              color="primary"
              fullWidth
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}>
              <Text k="BUTTON.LOGIN" />
            </Button>
          </Form>
        </Box.MEDIUM>
      )}
    </Formik>
  );
};

export default LoginForm;
