import React from 'react';

import { DragDropContext } from 'react-beautiful-dnd';

export const DragDropContextWrapper: React.FC<any> = ({ onDrag, isGrouped, children }) => {
  return onDrag && !isGrouped ? (
    <DragDropContext onDragEnd={onDrag}>{children}</DragDropContext>
  ) : (
    <div>{children}</div>
  );
};
