import styled from 'styled-components';
import { InputLabel } from '@mui/material';
import { SPACE } from '$gstyles';
import { mobileStyle } from '$gstyles/utils';

export const Wrapper = styled.div`
  width: 100%;
  .MuiTypography-root {
    font-size: 1.4rem;
  }

  .MuiInputBase-input {
    min-height: 21px;
  }

  ${(p) =>
    !p.isMultiline
      ? `
    .MuiFormGroup-root {
      flex-direction: row;
    }
    label.MuiFormControlLabel-root {
      height: 32px;
    }
  `
      : `
    .MuiOutlinedInput-multiline {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
    }
    label.MuiFormControlLabel-root {
      height: 36px;
    }
  `}

  .MuiInputBase-root {
    padding-top: 0px;
    padding: 11.5px 14px;
  }

  &.checkbox-group.small {
    .MuiInputBase-root {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .MuiInputBase-input {
      padding: 3.5px 10px;
    }
  }

  &.checkbox-group.xsmall {
    .MuiInputBase-root {
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .MuiInputBase-input {
      padding: 0.5px 10px;
    }
  }

  ${mobileStyle(`
    .MuiFormGroup-root {
      flex-direction: column;
    }
    label.MuiFormControlLabel-root {
      height: 36px;
    }
  `)}
`;

export const BSLabel = styled(InputLabel)`
  margin-top: ${SPACE.MEDIUM};

  &&.bs-label {
    position: absolute;
    top: -16px;
    left: 0;
    font-size: 13px;
    font-weight: 500;
    transform: none;
  }
`;
