import { lockClosedOutline, phonePortraitOutline } from 'ionicons/icons';

import { INPUT_TYPES } from '$gbusiness/enums';
import { requiredValidation, validatePhone } from '$gbusiness/helpers/input';
import InputRowModel from '$gbusiness/models/inputRow';

const LOGIN_FORM: Array<InputRowModel> = [
  {
    items: [
      {
        ...INPUT_TYPES.MOBILE,
        gridSize: 12,
        label: 'LABEL.PHONE',
        value: 'phone',
        icon: phonePortraitOutline,
        validation: [
          requiredValidation,
          {
            condition: (v) => !validatePhone(v),
            message: 'INVALID_PHONE',
          },
        ],
      },
      {
        ...INPUT_TYPES.PASSWORD,
        icon: lockClosedOutline,
        enterToSubmit: true,
        gridSize: 12,
        position: undefined,
        validation: [requiredValidation],
      },
    ],
  },
];

export default LOGIN_FORM;
