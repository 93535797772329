import React from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';

import { Text } from '$gintl';
import { authActions } from '$gbusiness/redux/auth';
import PATH from '$gbusiness/enums/paths';
import { Header, LoginForm } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { Box, SPACE, Space } from '$gstyles';
import { LogoHeader } from '$gcomponents/reusables';
import { Link } from '$gcomponents/primitives';
import { features } from '$configs';

interface LoginScreenProps {
  login: Function;
  onLogin?: Function;
}

const LoginScreen: React.FC<LoginScreenProps> = ({ login, onLogin }) => {
  const onSubmit = (values) => {
    if (onLogin) {
      onLogin(values);
      return;
    }
    login(values);
  };

  return (
    <IonPage id="login-screen">
      <Header title="SCREEN.LOGIN.TITLE" />
      <IonContent>
        <Box.LARGE>
          <LogoHeader />
          <LoginForm onSubmit={onSubmit} />
          <Space.Ver margin={SPACE.LARGE}>
            <div>
              <Link route={PATH.FORGOT} button>
                <Text k="SCREEN.LOGIN.FORGOT_LINK" />
              </Link>
            </div>
            {!features.disableRegister && (
              <div>
                <Link route={PATH.REGISTER} button>
                  <Text k="SCREEN.LOGIN.REGISTER_LINK" />
                </Link>
              </div>
            )}
          </Space.Ver>
        </Box.LARGE>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
});

const mapDispatchToProps = {
  login: authActions.login,
};

const connected: any = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(LoginScreen));
