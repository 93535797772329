import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';

import { ProfileScreenWrapper } from './styles';
import MainHeader from '$components/mainHeader';
import CurrentStateModel from '$business/models/currentState';
import MemberModel from '$business/models/member';
import ProfileComponent from '../groupScreen/profile';

interface ProfileScreenProps {
  member: MemberModel;
  currentState: CurrentStateModel;
}

const ProfileScreen: React.FC<ProfileScreenProps> = ({ member, currentState }) => {
  return (
    <ProfileScreenWrapper>
      <MainHeader disableMenu currentState={currentState} title={member?.name} />
      <IonContent>
        <ProfileComponent member={member} />
      </IonContent>
    </ProfileScreenWrapper>
  );
};

const connected = connect(null, null);

export default connected(screen(ProfileScreen));
