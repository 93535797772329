export const PATH = {
  LOGIN: '/login',
  FORGOT: '/forgotpassword',
  ONBOARDING: '/welcome',
  WAIT: '/wait',
  VERIFY: '/verifyCode',
  MESSAGE: '/message',
  HOME: '/home',
  GROUP: '/group',
  MYACCOUNT: '/myaccount',
  REVIEW: '/review',
  PROFILE: '/profile',
  THANKYOU: '/thankyou',
  ATTENDANCE: '/attendance',
  ABOUT_US: '/aboutus',
  SUPPORT: '/support',
};

export default PATH;
