import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .main-section {
    overflow: hidden;
  }
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 2rem;
  margin: ${SPACE.LARGE} 8px;
`;

export const GroupBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 150px;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast-bw);
  .count {
    opacity: 0.6;
    font-size: 0.9em;
  }
`;
