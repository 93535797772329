import React from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '$gcomponents/hoc';
import { userActions } from '$gbusiness/redux/user';

import { IonPageWrapper } from './styles';
import MainHeader from '$components/mainHeader';
import intl from '$gintl';
import { Form, Formik } from 'formik';
import { input } from '$gbusiness/helpers';
import MYACCOUNT_FORM from './myAccountForm';
import { Box, SPACE } from '$gstyles';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';

interface MyAccountScreenProps {
  onHydrate;
  onDehydrate;
  updateUser;
  user;
  currentState;
}

const MyAccountScreen: React.FC<MyAccountScreenProps> = ({ user, currentState, updateUser }) => {
  const accountForm = MYACCOUNT_FORM;

  const onSubmit = (values) => {
    const { name, phone, passwd } = values;
    const param = {
      name,
      phone,
      passwd: passwd || undefined,
    };
    updateUser(user.id, param);
  };

  const validateForm = (values) => {
    return input.validateError(accountForm, values);
  };

  return (
    <IonPageWrapper>
      <MainHeader currentState={currentState} backRoute="" title={intl('SCREEN.MYACCOUNT.TITLE')} />
      <IonContent>
        <Formik
          enableReinitialize={true}
          initialValues={user}
          validate={validateForm}
          onSubmit={(values) => {
            onSubmit(values);
          }}>
          {(formik) => (
            <Box.MEDIUM padding={SPACE.LARGE}>
              <Form>
                <FormSection title="" FORM={accountForm} formik={formik} marginBottom="0" />
                <Button
                  color="primary"
                  fullWidth
                  onClick={formik.handleSubmit}
                  disabled={!(formik.isValid && formik.dirty)}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Form>
            </Box.MEDIUM>
          )}
        </Formik>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.currentUser,
});

const mapDispatchToProps = {
  onDehydrate: userActions.dehydrate,
  updateUser: userActions.saveUser,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(MyAccountScreen));
