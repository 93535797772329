import styled from 'styled-components';
import { IonPage } from '@ionic/react';

export const ReviewScreenWrapper = styled(IonPage)`
  .text {
    margin-left: 10px;
  }
  .buttons {
    flex: none;
    width: 150px;
    > span {
      width: 150px;
      justify-content: space-between;
      display: flex;
    }
  }
  .no-result {
    display: flex;
    align-items: center;
    font-size: 1.1em;
    height: 100%;
    justify-content: center;
  }
`;
