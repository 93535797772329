import React from 'react';
import { useDispatch } from 'react-redux';

import GLoginModal from '$gcomponents/reusables/loginModal';
import { initActions } from '$business/redux/init';

interface LoginModalProps {
  [x: string]: any;
}

const LoginModal: React.FC<LoginModalProps> = ({ ...rest }) => {
  const dispatch = useDispatch();
  return <GLoginModal {...rest} onLogin={values => dispatch(initActions.login(values))} />;
};

export default LoginModal;
