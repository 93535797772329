import React from 'react';
import { connect } from 'react-redux';
import { screen } from '$gcomponents/hoc';

import { GridWrapper, IonPageWrapper } from './styles';
import CurrentStateModel from '$business/models/currentState';

import { IonContent } from '@ionic/react';
import { directoryActions } from '$business/redux/directory';
import GroupModel from '$business/models/group';
import MainHeader from '$components/mainHeader';
import Search from '$components/mainHeader/search';
import { PageWrapper } from '$gstyles/wrapper';
import GroupBox from './groupBox';
import PATH from '$business/enums/paths';

interface HomeScreenProps {
  currentState: CurrentStateModel;
  group: GroupModel;
  history;
}

const HomeScreen: React.FC<HomeScreenProps> = ({ history, currentState, group }) => {
  const handleGroupClick = (g) => {
    history.push(PATH.GROUP + '/' + g.id);
  };
  return (
    <IonPageWrapper>
      <MainHeader currentState={currentState} backRoute="" title={group?.name} />
      <IonContent>
        <PageWrapper>
          <Search />
          <GridWrapper>
            {(group?.items || []).map((g, i) => (
              <GroupBox key={i} group={g} onClick={handleGroupClick} />
            ))}
          </GridWrapper>
        </PageWrapper>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  group: state.directory.group,
});

const mapDispatchToProps = {
  onHydrate: (params) => directoryActions.fetchRootGroup(parseInt(params.rootId || 5)),
  onDehydrate: directoryActions.dehydrate,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(HomeScreen));
